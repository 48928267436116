@import 'common/utils/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.flexGap10 {
  display: flex;
  overflow-wrap: anywhere;
  gap: 10px;
}

@include breakpoint(lg) {
  .flexGap10 {
    flex-direction: column;
    gap: 0;
  }
}

@include breakpoint(md) {
  .flexGap10 {
    flex-direction: row;
    gap: 10px;
  }
}

@include breakpoint(sm) {
  .flexGap10 {
    flex-direction: column;
    gap: 5px;
  }

  .hideOnMobile {
    display: none;
  }
}
