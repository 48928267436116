@import 'common/utils/mixins';

.list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.box {
  max-width: 120px;
  max-height: 80px;
  width: 120px;
  height: 80px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: var(--background-light-input);
  border: 1px solid var(--box-stroke, var(--border-light));
  object-fit: contain;
  border-radius: 4px;
}

.youtubeLogoIcon {
  backdrop-filter: blur(5px);
  bottom: 5px;
  right: 5px;
  position: absolute;
  border-radius: 4px;
  padding: 3px;
  background: var(--youtube-logo-background);
}

.videoBox {
  cursor: pointer;
}

@include breakpoint(sm) {
  .youtubeLogoIcon {
    width: 40px;
    height: auto;
  }
}
