@import 'common/utils/mixins';

.container {
  width: 100%;
  margin-bottom: 40px;
}

.aprs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 10px;
}

.showMoreLess {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.moreAprs {
  margin-top: 20px;
}

@include breakpoint(lg) {
  .aprs {
    grid-template-columns: 1fr 1fr;
  }
}

@include breakpoint(sm) {
  .aprs {
    grid-template-columns: 1fr;
  }

  .showMoreLess {
    justify-content: flex-end;
  }
}
