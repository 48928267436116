.root {
  width: 100px;
  height: 100px;
  border: 1px dashed var(--border-theme);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  text-align: center;
}
