.headerCell {
  align-items: center;
  cursor: pointer;
  gap: 2px;
  overflow: hidden;
  min-width: 80px;
}

.sort {
  min-width: 9px;
  margin-left: 5px;
}

.sortless {
  cursor: default;
}
