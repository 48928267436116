.stateHealthy {
  background-color: var(--borrowpill-healthy-background);
  border-color: var(--borrowpill-healthy-border);
}

.stateNearMarginCall {
  background-color: var(--borrowpill-nearmargincall-background);
  border-color: var(--borrowpill-nearmargincall-border);
}

.stateMarginCall {
  background-color: var(--borrowpill-margincall-background);
  border-color: var(--borrowpill-margincall-border);
}

.stateLiquidation {
  background-color: var(--borrowpill-liquidation-background);
  border-color: var(--borrowpill-liquidation-border);
}

.stateLiquidated {
  background-color: var(--borrowpill-liquidated-background);
  border-color: var(--borrowpill-liquidated-border);
}

.stateCancelled {
  background-color: var(--borrowpill-cancelled-background);
  border-color: var(--borrowpill-cancelled-border);
}

.stateCompleted {
  background-color: var(--borrowpill-completed-background);
  border-color: var(--borrowpill-completed-border);
}
