@import 'common/utils/mixins';

.container {
  position: relative;
}

.icon {
  position: absolute;
  top: -30px;
  right: 10px;
}

.desc {
  display: block;
  max-width: 350px;
}

.button {
  width: 100%;
}

@include breakpoint(md) {
  .desc {
    max-width: 300px;
  }
}

@include breakpoint(sm) {
  .container {
    position: initial;
  }

  .icon {
    margin-top: 50px;
    position: initial;
    margin-left: calc((100vw - 110px) / 2);
  }

  .desc {
    max-width: 100vw;
  }
}
