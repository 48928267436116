@import 'common/utils/mixins';

.container {
  border-radius: 14px;
  background: var(--background-medium);
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 20px 0;
}

.row:last-child {
  margin-bottom: 0;
}

.donation {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nameBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
  word-break: break-all;
}

.btn {
  margin-top: 30px;
  width: 100%;
}

@include breakpoint(lg) {
  .container {
    padding: 20px;
  }
}
