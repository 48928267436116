.container {
  display: inline-flex;
  align-items: center;
}

.iconContainer {
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: linear;
  display: inline-flex;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
