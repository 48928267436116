.trackContainer {
  background: var(--background-light);
  height: 25px;
  cursor: pointer;
  display: flex;
  width: 100%;
}

.track {
  height: 25px;
  width: 100%;
  border-radius: 25px;
  margin: auto;
}

.thumb {
  border-radius: 35px;
  height: 35px;
  width: 35px;
  min-width: 35px;
  min-height: 35px;
  background-color: var(--background-slider-thumb);
  display: flex;
  justify-content: center;
  align-items: center;
}
