.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  min-width: 135px;
  cursor: pointer;
}

.disabled {
  background-color: var(--background-light-input);
}

.readOnly {
  border: none;
  cursor: default;
}
