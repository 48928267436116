@import 'common/utils/mixins';

.container {
  gap: 80px;
  display: flex;
  flex-direction: column;
}

.contentGrid {
  display: grid;
  gap: 40px;
  grid-template-areas: 'picture progress' 'header progress' 'donations progress' 'description progress';
  grid-template-columns: 580px 420px;
}

.pictureArea {
  grid-area: picture;
  line-height: 0;
}

.headerArea {
  grid-area: header;
}

.donationsArea {
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-area: donations;
}

.descriptionArea {
  grid-area: description;
}

.progressArea {
  grid-area: progress;
}

@include breakpoint(lg) {
  .contentGrid {
    grid-template-columns: 470px 340px;
  }
}

@include breakpoint(md) {
  .contentGrid {
    grid-template-areas: 'picture' 'header' 'progress' 'donations' 'description';
    grid-template-columns: 1fr;
  }
}
