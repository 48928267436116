@import 'common/utils/mixins';

.wallet {
  min-width: 160px;
  width: fit-content;
  margin-bottom: 40px;
}

@include breakpoint(sm) {
  .wallet {
    width: calc(100% - 20px);
  }
}
