@import 'common/utils/mixins';

.container {
  display: grid;
  grid-template-columns: 220px 1fr;
}

.options {
  margin-right: 40px;
}

.button {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  padding: 10px 20px;
  cursor: pointer;
}

.buttonActive {
  background-color: var(--background-secondary);
}

@include breakpoint(md) {
  .container {
    grid-template-columns: 100%;
  }

  .options {
    margin-right: 0;
    gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
  }
}
