.container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 20px;
}

.container:last-child {
  margin-bottom: 0;
}

.header {
  margin-bottom: 20px;
  display: block;
}
