.customRoot {
  width: 46px !important;
  height: 26px !important;
  padding: 0 !important;
  border-radius: 16px !important;
  border: none !important;
}

.customSwitchBase {
  padding: 3px !important;
}

.customSwitchThumb {
  color: white !important;
}

.customSwitchThumbError {
  background-color: var(--border-error) !important;
}

.customSwitchTrack {
  background-color: var(--background-checkbox) !important;
  opacity: 1 !important;
}

.customSwitchTrackError {
  background-color: var(--background-error) !important;
}

.customRootError {
  border: 2px solid var(--border-error) !important;
  height: 29px !important;
}

[class*='Mui-checked'] + .customSwitchTrack {
  background: var(--background-checkbox-checked) !important;
}
