.container {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.link {
  text-decoration: none;
}
