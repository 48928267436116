@import 'common/utils/mixins';

.container {
  display: flex;
  height: 210px;
  padding: 20px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px dashed var(--box-stroke, var(--border-light));
  background: var(--grey-fill, var(--background-light-input));
  position: relative;
}

.errorFile {
  border: 3px solid var(--border-error);
  background: var(--background-error);
}

.disabledBox {
  position: absolute;
  background: var(--background-light);
  opacity: 0.7;
  border: 1px solid var(--box-stroke, var(--border-light));
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.defaultContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.isDragContent {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropzone {
  border-radius: 5px;
  border: 2px dashed var(--border-theme);
  background: var(--background-dropzone-theme-gradient);
}

.button {
  border-radius: 5px;
  border: 1px solid var(--box-stroke, var(--border-light));
  background-color: transparent;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

@include breakpoint(sm) {
  .defaultContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }

  .container {
    padding: 10px 40px;
    text-align: center;
  }
}
