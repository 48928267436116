@import 'common/utils/mixins';

.networkSelectorsHeader {
  display: block;
  margin-block-end: 10px;
}

.networkSelectors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.networkSelectorsContainer {
  margin-block-end: 20px;
}

@include breakpoint(sm) {
  .networkSelectors {
    grid-template-columns: 1fr;
  }
}
