.container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.textNear {
  color: var(--borrow-maturity-near);
}

.textOverdue {
  color: var(--borrow-maturity-overdue);
}
