@import 'common/utils/mixins';

.row {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 3fr;
  grid-template-areas: 'currency walletName amount walletAddress';
  justify-items: start;
  align-items: center;
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 15px 20px;
  margin: 5px 0;
  gap: 5px;
}

.row:hover {
  background-color: var(--background-light-hover);
}

.row:hover .cellWalletAddress {
  opacity: 1;
}

.cellCurrency {
  grid-area: currency;
  min-width: 130px;
}

.cellWalletName {
  grid-area: walletName;
}

.walletIcon {
  display: none;
}

.mobileWalletName {
  display: none;
}

.cellAmount {
  grid-area: amount;
  overflow-wrap: anywhere;

  display: flex;
  flex-direction: column;
}

.cellWalletAddress {
  grid-area: walletAddress;
  overflow-wrap: anywhere;
  opacity: 0;
}

@include breakpoint(sm) {
  .row {
    grid-template-columns: 3fr 2fr;
    grid-template-areas: 'currency amount' 'walletName walletName' 'walletAddress walletAddress';
    row-gap: 5px;
    padding: 15px 10px;
  }

  .walletIcon {
    display: initial;
    margin-left: 35px;
    margin-right: 3px;
  }

  .walletName {
    display: none;
  }

  .mobileWalletName {
    display: initial;
  }

  .cellAmount {
    justify-self: end;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .cellWalletAddress {
    margin-left: 35px;
    opacity: 1;
    padding-right: 0;
  }
  .cellCurrency {
    min-width: none;
  }
}
