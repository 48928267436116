@import 'common/utils/mixins';

.row {
  align-items: center;
  display: grid;
  grid-template-columns: 3fr 3fr 7fr 170px;
  grid-template-areas: 'name network address actions';
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 15px 20px;
  margin: 5px 0;
  gap: 5px;
}

@media (hover: hover) {
  .row:hover {
    background-color: var(--background-light-hover);
  }
}

.cellName {
  grid-area: name;
  min-width: 150px;
  overflow-wrap: anywhere;
}

.cellNetwork {
  grid-area: network;
  overflow-wrap: anywhere;
  min-width: 150px;
}

.cellAddress {
  grid-area: address;
  overflow-wrap: anywhere;
}

.cellActions {
  display: flex;
  grid-area: actions;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

@include breakpoint(sm) {
  .row {
    grid-template-columns: auto 50px;
    grid-template-areas: 'name name' 'network network' 'address address' 'actionsc actions';
    padding: 20px;
    row-gap: 20px;
  }
}
