@import 'common/utils/mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.verticalLayout {
  flex-direction: row;
}

.subAmounts {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 20px 10px;
  margin: 40px 0 10px;
  max-width: 600px;
}

.moreSpace {
  gap: 40px 10px;
  margin: 40px 0;
  width: 600px;
}

.activeCount {
  margin-bottom: 15px;
}

.buttons {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
}

.spaceBetween {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: end;
}

@include breakpoint(xl) {
  .container {
    flex-direction: row;
    justify-content: space-between;
  }

  .subAmounts {
    gap: 20px;
    width: 100%;
  }
}

@include breakpoint(lg) {
  .subAmounts {
    grid-template-columns: 1fr;
  }
}

@include breakpoint(sm) {
  .container {
    flex-direction: column;
    height: fit-content;
  }

  .spaceBetween {
    height: fit-content;
    justify-content: start;
  }
}
