@import 'common/utils/mixins';

.container {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 30px 20px;
  margin: 10px 0;
}

.gap {
  gap: 20px;
}

.severityIcon {
  height: 13px;
  width: 13px;
  display: flex;
}

.error {
  background: var(--background-error-dark);
}

.success {
  background: var(--background-success);
}

.warning {
  background: var(--background-warning);
}

.secondary {
  background: var(--background-secondary);
  padding: 20px 30px;
}

.text {
  flex-grow: 1;
}

.closeIcon {
  cursor: pointer;
  width: 13px;
  height: 13px;
}

@include breakpoint(sm) {
  .container {
    padding: 15px 10px;
    gap: 10px;
  }
}
