@import 'common/utils/mixins';

.topContainer {
  align-items: center;
  background-color: var(--background-navbar-dark);
  display: flex;
  height: 90px;
  justify-content: space-between;
  padding: 0 40px;
}

.bottomContainer {
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;
  min-height: calc(100vh - 90px);
}

.sidebarContainer {
  background-color: var(--background-light);
  border-right: 1px solid var(--border-light);
  width: 240px;
  min-width: 240px;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 200;
}

.mainContainer {
  background-color: var(--background-dark);
  flex-grow: 1;
  max-width: calc(100vw - 240px);
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
  overflow-y: auto;
}

.sidebarArrowContainer {
  display: none;
}

.sidebarArrow {
  transform: rotate(-180deg);
}

@include breakpoint(xl) {
  .topContainer {
    padding: 0 20px;
  }

  .sidebarContainer {
    display: none;
    border: 0;
  }

  .sidebarExpanded {
    display: initial;
  }

  .sidebarArrowContainer {
    display: initial;
    background-color: var(--background-light);
    border-right: 1px solid var(--border-light);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 200;
  }

  .mainContainer {
    max-width: calc(100vw - 40px);
  }

  .mainContainerSidebarExpanded {
    margin-left: -240px;
  }
}

@include breakpoint(lg) {
  .sidebarContainer {
    width: 200px;
    min-width: 200px;
  }

  .mainContainerSidebarExpanded {
    margin-left: -200px;
  }
}

@include breakpoint(md) {
  .mainContainer {
    max-width: initial;
  }

  .mainContainerSidebarExpanded {
    margin-left: 0;
  }

  .sidebarArrowContainer {
    display: none;
  }

  .sidebarContainer {
    display: none;
  }
}
