@import 'common/utils/mixins';

.container {
  margin: 40px 0;
}

.flexBox {
  display: flex;
  justify-content: space-between;
}

.amountContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid var(--box-stroke, var(--border-light));
  background: var(--grey-fill, var(--background-light-input));
  padding: 20px 30px;
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
  max-height: 22px;
}

.inputContainer {
  width: 100%;
  height: 65px;
  position: relative;
}

.disabledBox {
  position: absolute;
  background: var(--background-light);
  opacity: 0.8;
  border: 1px solid var(--box-stroke, var(--border-light));
  height: 63px;
  width: 100%;
  margin-right: -32px;
  border-radius: 4px;
}

.input {
  text-align: right;
  background: transparent;
  padding: 0;
}

.errorInput {
  background: var(--background-error);
  border: 3px solid var(--border-error);
}

.networks {
  min-height: 275px;
}
