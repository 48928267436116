.container {
  padding: 12px 16px;
  width: fit-content;
  border: 1px solid var(--border-light);
  background: var(--background-light);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (hover: hover) {
  .container:hover {
    background-color: var(--background-light-hover);
  }
}

.selectedCurrency {
  border: 2px solid var(--border-light);
  background-color: var(--background-light-hover);
}
