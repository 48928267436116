.textarea {
  padding: 20px 30px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  margin: 5px 0px;
  word-wrap: break-word;
  font-family: 'Inter';
  resize: none;
  overflow: auto;
  outline: none;
}

.decimals {
  float: right;
}

.error {
  border: 3px solid var(--border-error);
}
