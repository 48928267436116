@import 'common/utils/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
  gap: 5px;
}

.copyIcon {
  padding: 4px;
  cursor: pointer;
}
