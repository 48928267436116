@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter';
  background: var(--background-light);
}

input {
  border: none;
  outline: none;
  font-family: 'Inter';
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Overwrite recharts behaviour so that there is no "border" when tooltip on chart is displayed */
.recharts-tooltip-wrapper:focus {
  outline: 0;
}

:root {
  --background-light: #ffffff;
  --background-light-hover: #fafafa;
  --background-light-input: #f9f9f9;
  --background-medium: #fafafa;
  --background-medium-hover: #efefef;
  --background-dark: #f5f5f5;
  --background-slider-thumb: #000000;
  --background-info: rgba(220, 220, 220, 0.19);
  --background-overlay: rgba(0, 0, 0, 0.1);
  --background-theme: #fffbd7;
  --background-theme-dark: #f1dc1f;
  --background-error: #ffeded;
  --background-error-dark: #ffe7da;
  --background-success: #d6fea3;
  --background-warning: #fffbd7;
  --background-secondary: rgba(220, 220, 220, 0.19);
  --background-navbar-dark: #3d3d3d;
  --background-navbar-light: #fcfcfc;
  --background-theme-gradient: linear-gradient(
    180deg,
    #ede300 0%,
    #ffd88d 100%
  );
  --background-theme-gradient-light: linear-gradient(
    180deg,
    rgba(237, 227, 0, 0.4) 0%,
    rgba(255, 216, 141, 0.4) 100%
  );
  --background-theme-shadow-opacity3: rgba(237, 171, 0, 0.3);
  --background-theme-shadow-opacity2: rgba(237, 171, 0, 0.2);
  --background-error-gradient: linear-gradient(
    180deg,
    rgba(248, 119, 119, 0.3) 0%,
    rgba(244, 112, 112, 0.3) 100%
  );
  --background-error-shadow: rgba(248, 119, 119, 0.3);
  --background-circle-number: #373737;
  --background-circle-remove: rgba(255, 255, 255, 0.6);

  --background-checkbox: #d8d8d8;
  --background-checkbox-checked: #f1dc1f;
  --background-notifications: #ff8946;
  --background-apr: #e0f6ff;

  --background-timer-path: #ff8946;
  --background-timer-path-error: #d96262;
  --background-timer-trail-light: #5b5b5b;
  --background-dropzone-theme-gradient: linear-gradient(
    102deg,
    rgba(255, 255, 248, 0.5) 0%,
    #fffff8 100%
  );

  --background-finished-slider: #fff7ad;

  --text-primary: #000000;
  --text-primary-greyout: #b7b7b7;
  --text-secondary: #808080;
  --text-light: #d8d8d8;
  --text-inactive: #b7b7b7;
  --text-theme: #fff37e;
  --text-theme-dark: #f6de45;
  --text-theme-greyout: #fff9bf;
  --text-error: #cc8989;
  --text-dark: #121212;
  --text-navbar-light: #ffffff;
  --text-input-info: #5c65bf;
  --text-error-dark: #d96262;

  --btn-light: #ffffff;
  --btn-dark: #3d3d3d;
  --btn-dark-greyout: #9b9b9b;

  --border-light: #eaeaea;
  --border-primary: #000;
  --border-light-focus: #bbc0f2;
  --border-medium: #d8d8d8;
  --border-theme: #efd90f;
  --border-theme-light: #fff37e;
  --border-dark: #212121;
  --border-navbar-light: #fff;
  --border-overlay: rgba(0, 0, 0, 0.15);
  --border-error: #f2bbbb;
  --border-error-dark: #d96262;
  --border-divider: rgba(0, 0, 0, 0.1);
  --border-status-pending: #ff8946;
  --border-confirmation-box-shadow: rgba(237, 171, 0, 0.3);
  --border-circle-box-shadow: rgba(223, 208, 131, 0.25);
  --border-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  --border-apr: #7eccf8;
  --border-input-info: #bbc0f2;
  --border-dropdown-shadow: drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.15));
  --border-apr-shadow: 0px 0px 18px rgba(0, 0, 0, 0.15);
  --border-circle-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.13);

  --common-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);

  --icon-theme: #efd90f;
  --icon-alert: #ef6619;
  --icon-loader: #9d9d9d;

  --borrowpill-healthy-background: #eef8d2;
  --borrowpill-healthy-border: #bfec3e;
  --borrowpill-repayment-background: #d5dffa;
  --borrowpill-repayment-border: #6275d9;
  --borrowpill-nearmargincall-background: #fffbd7;
  --borrowpill-nearmargincall-border: #efdb21;
  --borrowpill-margincall-background: #fde1d1;
  --borrowpill-margincall-border: #ff8946;
  --borrowpill-liquidation-background: #fad5d5;
  --borrowpill-liquidation-border: #d96262;
  --borrowpill-liquidated-background: #edb7b7;
  --borrowpill-liquidated-border: #9a0505;
  --borrowpill-cancelled-background: #ffd4e4;
  --borrowpill-cancelled-border: #ff808f;
  --borrowpill-completed-background: #dce7f2;
  --borrowpill-completed-border: #a9d4ee;

  --borrowalert-overcollateralized-background: #f9ffe8;
  --borrowalert-healthy-background: #ffffff;
  --borrowalert-nearmargincall-background: #fffbd7;
  --borrowalert-margincall-background: #feede3;
  --borrowalert-liquidation-background: #ffe9e9;
  --borrowalert-liquidated-background: #ffd5d5;
  --borrowalert-cancelled-background: #ffd4e4;
  --borrowalert-completed-background: #e9f3fd;

  --pill-active-background: #eef8d2;
  --pill-active-border: #bfec3e;
  --pill-completed-background: #dce7f2;
  --pill-completed-border: #a9d4ee;
  --pill-terminated-background: rgba(220, 220, 220, 0.19);
  --pill-terminated-border: #808080;

  --horizontal-health-gradient: linear-gradient(
    90deg,
    #97c160 0%,
    #b8cb49 51.04%,
    #f2dd21 70.31%,
    #f48f46 84.37%,
    #e77059 100%
  );

  --trade-custom-input-shadow: 0px 2px 30px rgba(0, 0, 0, 0.07);

  --borrow-maturity-near: #3448ac;
  --borrow-maturity-overdue: #cf4d4d;

  --bubble-chart-tooltip-shadow: 0px 6px 10px rgba(184, 184, 184, 0.25);

  --kycaddressplaceholder-background: rgba(220, 220, 220, 0.19);
  --kycaddressplaceholder-border: #808080;

  --balancechart-total: #9de6f5;
  --balancechart-available: #3373ad;
  --balancechart-reference: #b7b7b7;

  --range-dark-theme: #ede300;
  --range-light-theme: #ffd88d;

  --youtube-logo-background: rgba(255, 255, 255, 0.7);

  --charityformprogress-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.07);
}
