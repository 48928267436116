@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  border-radius: 6px;
  box-sizing: border-box;
  height: 100px;

  cursor: pointer;

  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 35px 35px;
  grid-template-areas: 'rate apr' 'rate term';
  column-gap: 10px;
  justify-content: center;
  align-content: center;
}

.selected {
  background: var(--background-theme-gradient);
  border: 0;
  box-shadow: 0px 6px 10px var(--background-theme-shadow);
}

.cellRate {
  grid-area: rate;
  justify-self: end;
  align-self: center;

  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
}

.smallerRateFont {
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
}

.cellApr {
  grid-area: apr;
  align-self: end;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.cellTerm {
  grid-area: term;
}

@include breakpoint(lg) {
  .cellRate.responsive {
    font-weight: 800;
    font-size: 30px;
    line-height: 36px;
  }

  .smallerRateFont {
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
  }

  .cellApr.responsive {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }
}

@include breakpoint(sm) {
  .container.responsive {
    height: 70px;
    grid-template-rows: 30px 30px;
    grid-template-areas: 'rate apr' 'term term';
    column-gap: 0;
  }

  .cellRate.responsive {
    justify-self: start;
    align-self: end;

    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
  }

  .smallerRateFont {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
  }

  .cellApr.responsive {
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
  }
}
