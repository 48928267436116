@import 'common/utils/mixins';

.container {
  position: relative;
}

.pictures {
  border-radius: 5px;
  width: 580px;
  height: auto;
  aspect-ratio: 4/3;
  position: relative;
}

.image {
  width: auto;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 4/3;
}

.arrow {
  border-radius: 50px;
  background: rgba(249, 249, 249, 0.8);
  box-shadow: var(--border-circle-shadow);
  position: absolute;
  bottom: calc(50% - 10px);
  z-index: 100;
  cursor: pointer;
}

.arrowLeft {
  right: 10px;
  transform: rotate(180deg);
}

.arrowRight {
  left: 10px;
}

.hideArrow {
  display: none;
}

.player {
  width: 100%;
  height: 100%;
}

@include breakpoint(lg) {
  .pictures {
    width: 470px;
  }
}

@include breakpoint(md) {
  .pictures,
  .image {
    width: calc(100vw - 40px);
  }
}
