@import 'common/utils/mixins';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 30px;
  gap: 10px;
  background: var(--background-secondary);
  border: 1px solid var(--border-light);
  border-radius: 4px;
  margin: 10px 0;
}
@include breakpoint(sm) {
  .address {
    word-break: break-all;
  }

  .container {
    padding: 10px 10px 10px 20px;
  }
}
