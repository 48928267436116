.container {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-right: 10px;
  padding: 12px 15px;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;
}

.border {
  border: 1px solid var(--border-navbar-light);
}

.borderActive {
  border: 1px solid var(--border-theme-light);
}

.borderDisabled {
  border: 1px solid var(--text-inactive);
}

.colorText {
  color: var(--text-navbar-light);
}

@media (hover: hover) {
  .colorText:hover {
    color: var(--text-theme);
  }

  .border:hover {
    border: 1px solid var(--border-theme-light);
  }
}
