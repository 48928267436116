@import 'common/utils/mixins';

.container {
  display: flex;
  gap: 20px;
  max-width: 1000px;
}

.dataFieldsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
}

.dataFields {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  max-width: 860px;
}

.dataField {
  min-width: 210px;
}

.secondaryDataFields {
  display: flex;
  gap: 30px;
}

.terminatedInfoBox {
  background: var(--background-light-input);
  border-radius: 4px;
  padding: 20px 30px;
}

.progressCircleContainer {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.progressCircleLabel {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.labelPart {
  text-align: center;
}

.wallet {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

@include breakpoint(lg) {
  .dataField {
    min-width: 130px;
  }

  .wallet {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

@include breakpoint(sm) {
  .dataFields {
    flex-direction: column;
    gap: 20px;
  }

  .secondaryDataFields {
    flex-direction: column;
    gap: 10px;
  }
}
