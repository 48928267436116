@import 'common/utils/mixins';

.container {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.descriptionContainer {
  max-width: 180px;
}

@include breakpoint(sm) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
