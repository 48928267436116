.button {
  background-color: var(--btn-light);
  border: 0;
  color: var(--text-primary);
  cursor: pointer;
  padding: 0;
  text-align: start;
  transition: all 0.2s ease-out;
}

@media (hover: hover) {
  .button:hover {
    color: var(--text-primary-greyout);
  }
}

.button:disabled {
  color: var(--text-primary-greyout);
}
