@import 'common/utils/mixins';

.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.summary {
  background-color: var(--background-medium);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 30px;
}

.field {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.wallet {
  min-width: 160px;
  width: fit-content;
}

.terminateButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

@include breakpoint(sm) {
  .wallet {
    width: auto;
  }
}
