.container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-right: -4px;
}

.periodButton {
  display: block;
  padding: 4px;
  cursor: pointer;
  background-color: var(--background-light);
}

@media (hover: hover) {
  .periodButton:hover {
    background-color: var(--background-light-hover);
  }
}
