.size15 {
  line-height: 0;
  svg {
    height: 15px;
    width: 15px;
    transform: scale(1);
  }
}

.size20 {
  line-height: 0;
  svg {
    height: 20px;
    width: 20px;
    transform: scale(1);
  }
}

.size24 {
  line-height: 0;
  svg {
    height: 24px;
    width: 24px;
    transform: scale(1);
  }
}

.size34 {
  line-height: 0;
  svg {
    height: 34px;
    width: 34px;
    transform: scale(1);
  }
}
