.container {
  background-color: var(--background-medium);
  border-radius: 4px;
  padding: 20px 30px;
  display: grid;
  gap: 10px;
  grid-template-columns: 4fr 6fr;
}

.summaryLabel {
  overflow-wrap: anywhere;
}

.summaryValue {
  justify-self: end;
  overflow-wrap: anywhere;
  text-align: right;
}
