.container {
  border: 1px solid var(--border-light);
  border-radius: 4px;
  padding: 30px 20px;
  min-width: 210px;
  box-sizing: border-box;
  cursor: pointer;
}

.container.selected {
  cursor: default;
  background: var(--background-medium);
}

@media (hover: hover) {
  .container:hover {
    background-color: var(--background-light-hover);
  }

  .container.selected:hover {
    background: var(--background-medium-hover);
  }
}

.error {
  border: 3px solid var(--border-error);
}
