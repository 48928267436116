@import 'common/utils/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.subheader {
  display: block;
  margin-top: 20px;
}

@include breakpoint(sm) {
  .container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 40px;
  }
}

.actionsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  min-width: 160px;
}

@include breakpoint(md) {
  .actionsContainer {
    flex-wrap: wrap;
  }
}
