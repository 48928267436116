.container {
  position: relative;
}

.switch {
  border: 1px solid var(--border-light);
  border-radius: 5px;
  
  cursor: pointer;
  
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 12px;

  padding: 8px 10px;
  box-sizing: border-box;
}

.popup {
  position: absolute;
  right: 0;
  top: -80px;
  border: 1px solid var(--border-light);

  border-radius: 6px;
  background: var(--background-light);
  padding: 15px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  min-width: 60px;
  box-shadow: var(--common-box-shadow);
}

.button {
  padding: 0;
  margin: 0;
  background: unset;
  border: 0;
  cursor: pointer;
}
