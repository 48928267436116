.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
}

.dot {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.container:hover {
  border-radius: 4px;
  background: var(--grey-fill, var(--background-light-input));
}

.isSelected {
  border-radius: 4px;
  border: 1px solid var(--button-gradient-y, var(--border-theme));
  background: var(--grey-fill, var(--background-light-input));
}
