.wallet {
  border: 1px solid var(--border-light);
  border-radius: 4px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.walletNetwork {
  display: flex;
  align-items: center;
  gap: 10px;
}
