@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  background-color: var(--background-light);
  border-radius: 4px;
  padding: 5px 10px;
}

.selectedWallet {
  background-color: var(--background-light-input);
}

.name {
  display: flex;
  align-items: center;
  gap: 7px;
}

.cursorPointer {
  cursor: pointer;
}

@include breakpoint(sm) {
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 10px;
  }
}
