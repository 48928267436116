@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  padding: 15px 20px;
  border-radius: 4px;
  margin: 5px 0;
}

.tableItem {
  display: grid;
  grid-template-columns: 25px 3fr 2fr 2fr 220px;
  grid-template-areas: 'expand name amount status suffix';
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.status {
  grid-area: status;
  min-width: 50px;
}

.suffix {
  grid-area: suffix;
}

.link {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.suffixMobile {
  display: none;
}

@include breakpoint(lg) {
  .tableItem {
    grid-template-columns: 25px 3fr 2fr 2fr 130px;
  }
}

@include breakpoint(md) {
  .tableItem {
    grid-template-columns: 25px 2fr 1fr;
    grid-template-areas:
      'extend name status'
      'amount amount amount'
      'suffix sufix sufix';
    align-items: center;
    gap: 10px 5px;
  }

  .moreInfo {
    padding-top: 10px;
  }

  .suffix {
    display: none;
  }

  .suffixMobile {
    display: flex;
    align-items: center;
    justify-content: end;
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .amount {
    grid-area: amount;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .status {
    display: flex;
    justify-content: end;
  }
}

@include breakpoint(sm) {
  .tableItem {
    grid-template-columns: 25px 1fr;
    grid-template-areas:
      'extend name'
      'status status'
      'amount amount'
      'suffix sufix';
  }

  .status {
    justify-content: start;
    grid-column-start: 2;
    grid-column-end: 3;
  }
}
