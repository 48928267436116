@import 'common/utils/mixins';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  margin: 20px 0;
  align-items: center;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.header {
  display: flex;
  gap: 5px;
  align-items: center;
}

.amount {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-top: 1px;
}

@include breakpoint(md) {
  .container {
    grid-template-columns: 2fr 1fr 1fr;
  }
}

@include breakpoint(sm) {
  .container {
    grid-template-columns: 2fr 1fr;
    justify-content: space-between;
    gap: 5px;
  }

  .amount {
    align-items: end;
    text-align: right;
  }
}
