@import 'common/utils/mixins';

.row {
  display: grid;
  grid-template-columns: 60px 2fr 2fr 2fr 3fr;
  grid-template-areas: 'empty currency currency amount emptyright ';
  column-gap: 5px;
  padding: 10px 0;
}

.cellCurrency {
  grid-area: currency;
  justify-self: start;
}

.cellAmount {
  grid-area: amount;
  overflow-wrap: anywhere;

  display: flex;
  flex-direction: column;
}

@include breakpoint(sm) {
  .row {
    grid-template-columns: 4fr 6fr;
    grid-template-areas: 'currency amount';
  }

  .cellAmount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }
}
