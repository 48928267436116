.container {
  align-items: center;
  cursor: pointer;
  gap: 2px;
  overflow: hidden;
  min-width: 80px;
}

.text {
  min-width: 9px;
  margin-left: 5px;
}
