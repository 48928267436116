@import 'common/utils/mixins';

.container {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.horizontalFields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: end;
  flex-grow: 1;
  gap: 20px;
}

.field {
  display: flex;
  flex-direction: column;
}

@include breakpoint(xl) {
  .horizontalFields {
    grid-template-columns: 1fr;
  }
}

@include breakpoint(md) {
  .container {
    grid-template-columns: 1fr;
  }

  .horizontalFields {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@include breakpoint(sm) {
  .horizontalFields {
    grid-template-columns: 1fr;
  }
}
