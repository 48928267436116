.container {
  background: var(--background-apr);
  border: 1px solid var(--border-apr);
  border-radius: 16px;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
}
