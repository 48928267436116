@import 'common/utils/mixins';

.container {
  border-radius: 20px;
  box-shadow: var(--charityformprogress-shadow);
  gap: 16px;
  padding: 30px;
  position: sticky;
  top: 0px;
}

.container,
.collectedFundsContainer,
.durationContainer,
.donatorsContainer,
.actionButtonsContainer {
  display: flex;
  flex-direction: column;
}

.actionButtonsContainer {
  gap: 10px;
}

.button {
  padding: 20px 30px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finished {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.hourglass {
  height: 47px;
  width: 37px;
}

@media (hover: hover) {
  .button:hover {
    opacity: 0.7;
  }
}

@include breakpoint(md) {
  .container {
    border-radius: unset;
    box-shadow: unset;
    padding: 0;
    position: unset;
    top: unset;
  }

  .actionButtonsContainer {
    padding-top: 24px;
  }
}
