@import 'common/utils/mixins';

.address {
  word-break: break-all;
}

.wallet {
  min-width: 160px;
  width: fit-content;
  margin-bottom: 40px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

@include breakpoint(sm) {
  .wallet {
    width: calc(100% - 20px);
  }
}
