@import 'common/utils/mixins';

.container {
  margin-top: 180px;
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.columns {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  margin-bottom: 60px;
  align-items: center;
}

.button {
  width: 100%;
}

.icon {
  min-width: 90px;
  min-height: 90px;
  margin-left: 40px;
}

@include breakpoint(sm) {
  .container {
    margin-top: unset;
  }
  .icon {
    margin-left: unset;
  }
}
