.logoBox {
  margin-top: 5px;
  position: relative;
  max-width: 120px;
  max-height: 80px;
  width: 120px;
  height: 80px;
}

.image {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 4px;
  object-fit: contain;
  background: var(--background-light-input);
  border: 1px solid var(--box-stroke, var(--border-light));
}

.removeIcon {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: var(--background-circle-remove);
  box-shadow: var(--border-circle-shadow);
  backdrop-filter: blur(11px);
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 5px;
  cursor: pointer;
}

.section {
  margin: 20px 0;
}

.previewSection {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
