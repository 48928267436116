.notification {
  background: var(--background-notifications);
  border-radius: 50%;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notActiveCircle {
  background: var(--background-checkbox);
}
