.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-bottom: 10px;
  background: var(--background-light);
  border: 1px solid transparent;
  border-radius: 4px;
  width: calc(100% - 20px);
}

.container:first-child {
  margin-top: 10px;
}
