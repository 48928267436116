.filter {
  align-items: center;
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.container {
  line-height: 0;
  position: relative;
  z-index: 10;
}

.menuContainer {
  background-color: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 8px;
  box-shadow: var(--common-box-shadow);
  min-width: fit-content;
  white-space: nowrap;
  position: absolute;
  top: 10px;
  right: 0px;
  padding: 20px;
}

.positionLeft {
  left: 0px;
}

.positionRight {
  right: 0px;
}

.filterItems {
  margin: 10px 0;
  cursor: pointer;
}

.filterItems:first-child {
  margin-top: 0;
}

.filterItems:last-child {
  margin-bottom: 0;
}

.arrowUp {
  transform: rotate(180deg);
}
