.container {
  box-shadow: var(--border-box-shadow);
  border-radius: 6px;
  padding: 15px;
  margin-top: 20px;
  margin-right: 28px;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stateHealthy {
  background-color: var(--borrowalert-healthy-background);
}

.stateOvercollateralized {
  background-color: var(--borrowalert-overcollateralized-background);
}

.stateNearMarginCall {
  background-color: var(--borrowalert-nearmargincall-background);
}

.stateMarginCall {
  background-color: var(--borrowalert-margincall-background);
}

.stateLiquidation {
  background-color: var(--borrowalert-liquidation-background);
}

.stateLiquidated {
  background-color: var(--borrowalert-liquidated-background);
}

.stateCancelled {
  background-color: var(--borrowalert-cancelled-background);
}

.stateCompleted {
  background-color: var(--borrowalert-completed-background);
}
