@import 'common/utils/mixins';

.container {
  max-width: 100%;
  max-height: 135px;
  width: 100%;
  height: 135px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 0px var(--background-overlay);
}

.image {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  object-fit: cover;
  border-radius: 4px;
}
