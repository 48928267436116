@import 'common/utils/mixins';

.header {
  align-items: center;
  display: grid;
  grid-gap: 10px;
  padding: 15px 20px;
  grid-template-columns: 25px 3fr 2fr 2fr 220px;
  grid-template-areas: 'expand name amount status suffix';
}

@include breakpoint(lg) {
  .header {
    grid-template-columns: 25px 3fr 2fr 2fr 130px;
  }
}

@include breakpoint(md) {
  .header {
    display: none;
  }
}
