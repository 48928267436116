.termsConditionsCheckbox {
  display: flex;
  gap: 10px;
  align-items: center;
}

.termsConditionsLink {
  text-decoration: underline;
  cursor: pointer;
}
