.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.title {
  display: flex;
  align-items: center;
  gap: 5px;
}
