@import 'common/utils/mixins';

.container {
  padding: 20px 10px;
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 1fr;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.notificationName {
  display: flex;
  align-items: center;
  gap: 15px;
}

.notificationNameText {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.mainContainer {
  display: flex;
  border-bottom: 1px solid var(--border-light);
  cursor: pointer;
}

@media (hover: hover) {
  .mainContainer:hover {
    background-color: var(--background-light-hover);
  }
}

.smallMenu {
  grid-template-columns: 100%;
}

.boxMarginSmall {
  margin-left: 25px;
}

.id {
  margin-top: -5px;
}

.details {
  display: flex;
  justify-content: right;
}

@include breakpoint(lg) {
  .alignRight {
    text-align: right;
  }
}

@include breakpoint(sm) {
  .container {
    grid-template-columns: 100%;
  }

  .alignRight {
    text-align: left;
  }

  .boxMarginDesktop {
    margin-left: 25px;
  }
}
