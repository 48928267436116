@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 30px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px 5px;
  margin-top: 20px;
}

.bottomText {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

@include breakpoint(xl) {
  .container {
    padding: 20px;
  }
}

@include breakpoint(lg) {
  .details {
    grid-template-columns: 1fr;
  }
}

@include breakpoint(md) {
  .details {
    grid-template-columns: 1fr 1fr;
  }
}

@include breakpoint(sm) {
  .details {
    grid-template-columns: 1fr;
  }
}
