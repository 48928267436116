.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.address {
  border-top: 1px solid var(--border-light);
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-wrap: anywhere;
  cursor: pointer;
}
