@import 'common/utils/mixins';

.layout {
  justify-content: center;
}

.kycAlert {
  display: none;
}

@include breakpoint(sm) {
  .kycAlert {
    display: block;
    margin: -20px 0 40px;
  }
}
