.container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.baseTile {
  background: var(--background-medium);
  border: 1px solid var(--border-light);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 25px;
  width: 98px;
}

.currentTile {
  padding: 26px;
  background: var(--background-theme-gradient);
  border: 0;
  box-shadow: 0px 6px 10px var(--background-theme-shadow-opacity3);
}

.errorTile {
  background: var(--background-error-gradient);
  border: 1px solid var(--border-error-dark);
  box-shadow: 0px 6px 10px var(--background-error-shadow);
}

.nextTile {
  padding: 20px;
  margin: 5px 0;
  width: 108px;
}

.arrowheadContainer {
  padding: 8px;
}

.numberBadge {
  width: 23px;
  height: 23px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numberBadgeActive {
  background-color: var(--background-navbar-dark);
}

.numberBadgeInactive {
  background-color: var(--border-light);
}
