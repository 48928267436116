.textarea {
  box-sizing: border-box;
  padding: 15px 0px 15px 30px;
  width: 100%;
  min-height: 60px;
  background: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 4px;
  resize: none;
  font-family: inherit;
}

.textarea::placeholder {
  color: var(--text-secondary);
}

.textarea:active,
.textarea:focus {
  outline: none;
}

.headerText {
  margin: 8px 0;
}

.errorInput {
  background: var(--background-error);
  border: 3px solid var(--border-error);
}
