@import 'common/utils/mixins';

.positioningContainer {
  position: relative;
}

.contentUnderneathBar {
  margin-top: -65px;
}

@include breakpoint(md) {
  .contentUnderneathBar {
    margin-top: 0px;
  }
}
