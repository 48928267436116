.infolayer {
  background-color: var(--background-light);
  pointer-events: none;
  position: absolute;
  color: #000;
  width: 165px;
  max-width: 165px;
  padding: 10px;
  box-shadow: var(--bubble-chart-tooltip-shadow);

  border-radius: 5px;
  animation-name: fade-in;
  animation-duration: 0.2s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  .assetLayout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  }

  .amount {
    text-align: right;
  }

  .pct {
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-align: right;
  }

  .network {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
}
