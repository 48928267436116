@import 'common/utils/mixins';

.container {
  display: flex;
  align-items: center;
}

.photoUploaded {
  gap: 5px;
}

.list {
  display: flex;
  align-items: center;
  gap: calc((100% - 600px) / 5);
  width: 100%;
}

.box {
  max-width: 120px;
  max-height: 80px;
  width: 120px;
  height: 80px;
  border-radius: 4px;
  background: var(--background-light-input);
  border: 1px solid var(--box-stroke, var(--border-light));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0px 5px 5px 0px;
}

.box:last-child {
  margin-right: 0px;
}

.firstBox {
  max-width: 135px;
  max-height: 95px;
  width: 135px;
  height: 95px;
  border-radius: 5px;
  border: 2px solid var(--background-theme-dark);
  margin-top: 0px;
}

.image {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  object-fit: contain;
  cursor: pointer;
  border-radius: 4px;
}

.firstImage {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
}

.isImageDropping {
  box-shadow: 0px 0px 8px var(--border-overlay);
}

.removeIcon {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: var(--background-circle-remove);
  box-shadow: var(--border-circle-shadow);
  backdrop-filter: blur(11px);
  top: 5px;
  right: 5px;
  position: absolute;
}

.youtubeLogoIcon {
  backdrop-filter: blur(5px);
  bottom: 5px;
  right: 5px;
  position: absolute;
  border-radius: 4px;
  padding: 3px;
  background: var(--youtube-logo-background);
}

@include breakpoint(md) {
  .box {
    height: 70px;
  }

  .firstBox {
    width: 120px;
    height: 80px;
  }
}

@include breakpoint(sm) {
  .list {
    gap: calc((100% - 400px) / 5);
  }

  .box {
    width: 80px;
    height: 50px;
  }

  .firstBox {
    width: 100px;
    height: 60px;
  }

  .youtubeLogoIcon {
    width: 40px;
    height: auto;
  }
}
