.ltvAndPill {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.chart {
  margin-top: 20px;
  margin-left: -28px;
}
