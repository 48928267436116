.container {
  display: grid;
  grid-template-columns: 44px auto;
  grid-template-areas: 'initials name' 'initials email' 'initials role' 'initials companyName';
  column-gap: 20px;
}

.initials {
  grid-area: initials;
  align-self: center;
}

.name {
  grid-area: name;
}

.email {
  grid-area: email;
}

.role {
  grid-area: role;
}

.companyName {
  grid-area: companyName;
}
