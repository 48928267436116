@import 'common/utils/mixins';

.kycAlert {
  display: none;
}

.layout {
  justify-content: center;
}

.alternativeOffer {
  border: 1px solid var(--border-theme);
  box-shadow: 0px 6px 10px rgba(223, 208, 131, 0.25);
}

@include breakpoint(md) {
  .alternativeOffer {
    border: none;
    box-shadow: none;
  }

  .rate {
    display: none;
  }
}

@include breakpoint(sm) {
  .kycAlert {
    display: block;
    margin: -20px 0 40px;
  }
}
