@import 'common/utils/mixins';

.button {
  padding: 20px 30px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.secondary {
  background: transparent;
  border: 1px solid var(--border-light);
  color: var(--text-primary);
}

.small {
  padding: 8px 16px;
}

.dark {
  color: var(--text-theme);
  background: var(--btn-dark);
  border: 1px solid var(--btn-dark);
}

.dark.disabled {
  color: var(--text-theme-greyout);
  background: var(--btn-dark-greyout);
  border: 1px solid var(--btn-dark-greyout);
}

.secondary.disabled {
  color: var(--text-primary-greyout);
}

@media (hover: hover) {
  .dark:hover {
    color: var(--text-theme-greyout);
    background: var(--btn-dark-greyout);
    border: 1px solid var(--btn-dark-greyout);
  }

  .secondary:hover {
    color: var(--text-primary-greyout);
  }
}

.button.disabled {
  cursor: default;
}

@include breakpoint(md) {
  .secondary.disabled {
    color: var(--text-primary-greyout);
  }

  .dark.disabled {
    color: var(--text-theme-greyout);
    background: var(--btn-dark-greyout);
    border: 1px solid var(--btn-dark-greyout);
  }

  @media (hover: hover) {
    .secondary:hover {
      color: var(--text-primary-greyout);
    }

    .dark:hover {
      color: var(--text-theme-greyout);
      background: var(--btn-dark-greyout);
      border: 1px solid var(--btn-dark-greyout);
    }

    .secondary:hover,
    .dark:hover {
      opacity: 1;
    }
  }
}

@include breakpoint(sm) {
  .secondaryResponsive {
    padding: 8px 16px;
  }
}
