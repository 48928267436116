@import 'common/utils/mixins';

.catchClickContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 199;
}

.bodyScrollLock {
  height: 100%;
  overflow: hidden;
}

.container {
  display: none;
}

.userInfoContainer {
  margin: 30px 40px;
}

.featureButtonsContainer {
  margin: 15px 20px;
}

.settingButtonsContainer {
  border-top: 1px solid var(--border-light);
  padding-top: 20px;
  margin: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.topBorder {
  border-top: 1px solid var(--border-light);
  padding-top: 20px;
}

.bottomContainer {
  margin: 30px 20px 65px;
}

.fiatSwitchContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

@include breakpoint(md) {
  .container {
    display: flex;
    z-index: 200;
    min-width: 320px;
    height: calc(100vh - 90px);
    top: 90px;
    right: 0;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    background: var(--background-light);
    overflow: auto;
    box-shadow: 0px 0px 8px var(--border-overlay);
    border-left: 1px solid var(--border-light);
  }

  .burgerIcon {
    cursor: pointer;
    border: 1px solid var(--border-navbar-light);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('./assets/burger-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
}

@include breakpoint(sm) {
  .container {
    width: 100vw;
    border-left: 0;
  }

  .closeIcon {
    background-image: url('../../common/assets/white-icons/close.svg');
  }

  .catchClickContainer {
    top: 90px;
  }
}
