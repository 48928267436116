@import 'common/utils/mixins';

.containerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  border: 1px solid var(--border-light);
  border-radius: 8px;
  margin: 5px 0;
}

.containerBox {
  padding: 40px;
  border: 1px solid var(--border-light);
  box-shadow: var(--border-apr-shadow);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 20px;
}

.nameBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nameRow {
  display: flex;
  gap: 60px;
  align-items: center;
}

.smallText {
  display: none;
}

.btn {
  width: 100%;
  min-width: 125px;
}

@include breakpoint(xl) {
  .containerBox {
    padding: 20px;
  }

  .text {
    display: none;
  }

  .smallText {
    display: initial;
  }
}

@include breakpoint(lg) {
  .text {
    display: initial;
  }

  .smallText {
    display: none;
  }
}

@include breakpoint(sm) {
  .containerBox {
    flex-direction: row;
    height: auto;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .btnBox {
    width: auto;
    height: fit-content;
  }

  .containerRow {
    padding: 20px;
  }

  .nameRow {
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}
