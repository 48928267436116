@import 'common/utils/mixins';

.box {
  box-sizing: border-box;
  padding: 20px 0 20px 30px;
  width: 100%;
  height: auto;
  background: var(--background-light-input);
  border: 1px solid var(--border-light);
  border-radius: 4px;
}

.box::placeholder {
  color: var(--text-secondary);
}

.box:active,
.box:focus {
  outline: none;
}

.headerText {
  display: block;
  margin: 8px 0;
}

.errorBox {
  background: var(--background-light-input);
  border: 3px solid var(--border-error);
}

.nameBox {
  width: 100%;
  cursor: pointer;
  overflow-wrap: anywhere;
}

.address {
  width: 100%;
  cursor: pointer;
  overflow-wrap: anywhere;
  margin-top: 5px;
}

.boxWithIconBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.arrowDown {
  margin-right: 15px;
}

.cursorPointer {
  cursor: pointer;
}

.valueText {
  margin-bottom: -5px;
}

.displayNone {
  display: none;
}

@include breakpoint(sm) {
  .box {
    padding: 10px 0 10px 15px;
  }

  .address {
    margin-top: 0;
  }
}
