.subText {
  display: flex;
  align-items: center;
  gap: 15px;
}

.breakText {
  word-break: break-word;
}

.icon {
  margin: 0 5px 2px 0;
}
