@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  border-radius: 10px;
  box-shadow: var(--common-box-shadow);
  background: var(--background-light);
  padding: 40px 60px;
}

.outerContainer {
  padding: 40px 40px 0;
}

.outerContainer:last-child {
  padding-bottom: 40px;
}

@include breakpoint(lg) {
  .container {
    padding: 20px 30px;
  }

  .outerContainer {
    padding: 20px 20px 0;
  }

  .outerContainer:last-child {
    padding-bottom: 20px;
  }
}

@include breakpoint(md) {
  .outerContainer {
    padding: 0;
  }

  .container {
    border: 0;
    box-shadow: none;
    padding: 40px 20px;
  }
}
