@import 'common/utils/mixins';

.container {
  padding: 30px;
  gap: 10px;
  border: 1px solid var(--border-light);
  border-radius: 8px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
}

.headerMargin {
  margin-bottom: 30px;
}

.actions {
  min-width: 100px;
}

@include breakpoint(sm) {
  .container {
    padding: 30px 15px;
  }
}
