.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text {
  margin-right: -5px;
}

.tooltip {
  max-width: 130px !important;
}
