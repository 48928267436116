@import 'common/utils/mixins';

.configSection {
  padding: 40px 60px;
}

.optionBtns {
  margin-bottom: 40px;
}

.kycAlert {
  display: none;
}

@include breakpoint(sm) {
  .kycAlert {
    display: block;
    margin-bottom: 40px;
  }
}

@include breakpoint(lg) {
  .configSection {
    padding: 30px 50px;
  }
}

@include breakpoint(md) {
  .configSection {
    padding: 40px 20px;
    width: 100%;
  }
}
