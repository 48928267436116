.text {
  font-family: 'Inter';
}

.light1012 {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

.xxxbold1113 {
  font-weight: 900;
  font-size: 11px;
  line-height: 13px;
}

.bold1115 {
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
}

.light1215 {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.regular1215 {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.xbold1215 {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.xbold1214 {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.xxbold1218 {
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
}

.xbold1219 {
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
}

.xxbold1220 {
  font-weight: 800;
  font-size: 12px;
  line-height: 20px;
}

.light1417 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.xxbold1420 {
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
}

.light1421 {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.light1425 {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
}

.regular1416 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.bold1417 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.bold1422 {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.bold1425 {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
}

.xbold1417 {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.xbold1425 {
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
}

.xxbold1417 {
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
}

.xxbold1425 {
  font-weight: 800;
  font-size: 14px;
  line-height: 25px;
}

.light1619 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.light1622 {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.light1624 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.regular1622 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.bold1622 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.bold1619 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.xbold1619 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.xxbold1619 {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
}

.light1822 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.light1827 {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.xbold1821 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

.light2028 {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

.regular2032 {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.xbold2028 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.xxbold2024 {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
}

.xxbold2227 {
  font-weight: 800;
  font-size: 22px;
  line-height: 27px;
}

.xxbold2233 {
  font-weight: 800;
  font-size: 22px;
  line-height: 33px;
}

.xxbold2630 {
  font-weight: 800;
  font-size: 26px;
  line-height: 30px;
}

.xxbold2648 {
  font-weight: 800;
  font-size: 26px;
  line-height: 48px;
}

.regular3042 {
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
}

.bold3048 {
  font-weight: 600;
  font-size: 30px;
  line-height: 48px;
}

.xxbold3030 {
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
}

.light3248 {
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
}

.xxbold3248 {
  font-weight: 800;
  font-size: 32px;
  line-height: 48px;
}

.xxbold3644 {
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
}

.xxbold3048 {
  font-weight: 800;
  font-size: 30px;
  line-height: 48px;
}

.xxbold4056 {
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
}

.primaryColor {
  color: var(--text-primary);
}

.secondaryColor {
  color: var(--text-secondary);
}

.themeColor {
  color: var(--text-theme);
}

.themeDarkColor {
  color: var(--text-theme-dark);
}

.lightColor {
  color: var(--text-light);
}

.inactiveColor {
  color: var(--text-inactive);
}

.lightNavbarColor {
  color: var(--text-navbar-light);
}

.errorColor {
  color: var(--text-error);
}

.inputInfoColor {
  color: var(--text-input-info);
}

.errorDarkColor {
  color: var(--text-error-dark);
}

.letterSpacingSmall {
  letter-spacing: 0.02em;
}
