@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 30px;
}

.name {
  display: block;
  margin-bottom: 10px;
}

.boxName {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 20px;
}

.flexBox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 30px 0;
}

.collectedFunds {
  margin-top: 20px;
}

.progressBar {
  margin: 10px 0;
}

.buttons {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: end;
  min-width: 190px;
}

@include breakpoint(xl) {
  .container {
    padding: 20px;
  }
}

@include breakpoint(lg) {
  .flexBox {
    grid-template-columns: 1fr 1fr;
  }

  .buttons {
    min-width: auto;
    max-width: 105px;
  }

  .button {
    width: 100%;
  }
}

@include breakpoint(md) {
  .flexBox {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .buttons {
    min-width: 190px;
    max-width: auto;
  }

  .button {
    width: fit-content;
  }
}

@include breakpoint(sm) {
  .flexBox {
    grid-template-columns: 1fr 1fr;
  }

  .buttons {
    min-width: auto;
    max-width: 105px;
  }

  .button {
    width: 100%;
  }
}
