.container {
  display: flex;
  flex-direction: column;
}

.button {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.headerText {
  margin-bottom: 20px;
}
