.container {
  background: var(--background-light-hover);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  gap: 6px;
  border-radius: 10px;
}

.showLess {
  transform: rotate(180deg);
}
