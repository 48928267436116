@import 'common/utils/mixins';

.grid {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr;
  margin: 60px 0 40px;
}

@include breakpoint(sm) {
  .grid {
    grid-template-columns: 100%;
  }
}
