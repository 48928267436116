@import 'common/utils/mixins';

.container {
  display: flex;
  gap: 30px;
  align-items: center;
}

.button {
  width: 80px;
  padding: 8px 16px;
}

@include breakpoint(sm) {
  .container {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .button {
    display: none;
  }
}
