@import 'common/utils/mixins';

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
  justify-content: space-between;
}

.fiatSwitchContainer {
  margin: 0px 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@include breakpoint(lg) {
  .fiatSwitchContainer {
    margin: 0px 10px 10px;
  }
}
