.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: 100%;
}

.text {
  text-align: center;
  display: block;
  max-width: 300px;
}
