@import 'common/utils/mixins';

.detailsContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.interestContainer {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
}

.networkAndWallet {
  display: flex;
  align-items: center;
  gap: 10px;
}

@include breakpoint(lg) {
  .detailsContainer {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@include breakpoint(sm) {
  .detailsContainer {
    grid-template-columns: 100%;
  }

  .interestContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
