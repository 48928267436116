@import 'common/utils/mixins';

.section {
  border: 1px solid var(--border-light);
  border-radius: 10px;
  box-shadow: var(--common-box-shadow);
  background: var(--background-light);
}

.configSection {
  width: 1100px;
  padding: 60px 180px;
  box-sizing: border-box;
}

.summarySection {
  width: 500px;
  padding: 60px 80px;
  box-sizing: border-box;
}

@include breakpoint(xxl) {
  .configSection {
    width: 890px;
    padding: 60px 100px;
  }

  .summarySection {
    width: 390px;
    padding: 60px 40px;
  }
}

@include breakpoint(lg) {
  .configSection {
    padding: 30px 50px;
  }

  .summarySection {
    padding: 30px 20px;
  }
}

@include breakpoint(md) {
  .section {
    border: 0;
    box-shadow: none;
    border-radius: 0;
  }

  .configSection {
    padding: 40px 20px 20px;
    width: 100%;
  }

  .summarySection {
    padding: 20px 20px 40px;
    width: 100%;
  }
}
