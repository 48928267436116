.container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.tooltip {
  width: 160px;
}
