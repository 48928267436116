.container {
  background-color: var(--background-medium);
  border: 1px solid var(--border-medium);
  border-radius: 4px;
  display: inline-flex;
  cursor: pointer;
}

.button {
  padding: 8px 14px;
  border-radius: 4px;
  display: inline-flex;
  line-height: 0;
  margin: -1px;
}

.active {
  background-color: var(--btn-dark);
}
