@import 'common/utils/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
}

@include breakpoint(sm) {
  .buttonsContainer {
    flex-direction: column-reverse;
    width: 100%;
  }
}
