.container {
  margin: 20px 0;
}

.inputContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: var(--background-light);
  border: 1px solid var(--border-light);
  box-sizing: border-box;
  padding: 15px 30px 15px 30px;
  border-radius: 4px;
}

.input {
  width: 100%;
  margin-right: 20px;
  border: none;
  outline: none;
}

.input::placeholder {
  color: var(--text-secondary);
}

.headerText {
  margin: 8px 0;
}

.showPassword {
  cursor: pointer;
}

.errorInputContainer {
  background: var(--background-error);
  border: 3px solid var(--border-error);
}

.errorInput {
  background: var(--background-error);
}
