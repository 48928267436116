.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5px 10px;
  gap: 3px;
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
}
