.container {
  position: relative;
}

.addingBox {
  border-radius: 4px;
  background: var(--grey-fill, var(--background-light-input));
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  cursor: pointer;
}

.addingBox:hover {
  border: 1px solid var(--button-gradient-y, var(--border-theme));
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  width: 100%;
  margin-top: 20px;
}

.modal {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 290px;
  height: 420px;
  padding: 18px 16px;
  border-radius: 4px;
  background: var(--background-light);
  position: absolute;
  filter: drop-shadow(0px 0px 8px var(--border-overlay));
  bottom: 52px;
}
