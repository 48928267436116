@import 'common/utils/mixins';

.container {
  padding: 20px;
  margin: 0 auto;
  width: 1040px;
}

@include breakpoint(lg) {
  .container {
    width: 850px;
  }
}

@include breakpoint(md) {
  .container {
    width: calc(100% - 40px);
  }
}
