.slider {
  width: 90px;
  height: 90px;
  background: var(--background-theme-gradient);
  filter: drop-shadow(0px 5.39917px 8.99862px var(--border-circle-box-shadow));
  border-radius: 50%;
  overflow: hidden;
}

.wave {
  background: repeat center url('./assets/wave.svg');
  height: 110px;
  width: 500px;
}

.completed {
  display: flex;
  align-items: center;
  justify-content: center;
}
