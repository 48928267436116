@import 'common/utils/mixins';

.container {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 10px 10px 30px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
}

.amountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.currencyBox {
  padding: 10px;
  border-radius: 4px;
}

.inputCurrency {
  display: none;
}

@include breakpoint(sm) {
  .inputCurrency {
    display: inline;
  }

  .currencyBox {
    display: none;
  }

  .container {
    padding: 15px 10px;
  }
}
