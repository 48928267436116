.container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.name {
  overflow-wrap: break-word;
  width: calc(100% - 20px);
}

.icon {
  min-width: 15px;
}
