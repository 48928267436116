@import 'common/utils/mixins';

.customizedModal {
  padding: 35px 50px 50px !important;
  max-height: 100vh !important;
}

.customizedModalHeader {
  margin-bottom: 35px !important;
}

.iframe {
  border: 0;
  width: 100%;
  height: 700px;
  max-height: calc(100vh - 150px);
}

.footer {
  text-align: center;
}

@include breakpoint(sm) {
  .customizedModal {
    padding: 20px !important;
  }

  .customizedModalHeader {
    margin-bottom: 20px !important;
  }

  .iframe {
    height: 750px;
    max-height: unset;
  }
}
