.saturationBox {
  height: 280px;
  width: 100%;
  position: relative;
  border-radius: 10px;
  border: 2px solid transparent !important;

  div {
    border-radius: 5px;
    box-shadow: none !important;
    cursor: pointer;
  }
}

.hueBox {
  width: calc(100%);
  margin: 12px 0 15px;
  height: 15px;
  position: relative;

  div {
    border-radius: 24px;
    border: 1px solid transparent;
    cursor: pointer;
  }
}

.inputs,
.titles {
  display: grid;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  width: 100%;
  margin-bottom: 5px;
}

.title {
  display: block;
  width: 100%;
}

.input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--box-stroke, var(--border-light));
  background: #fff;
  padding: 8px 12px;
  height: 40px;
}

.selectedColor {
  width: 100%;
  height: 30px;
  padding: 5px 0;
  border-radius: 4px;
  border: 1px solid var(--box-stroke, var(--border-light));
  margin-bottom: 10px;
  text-align: center;
}
