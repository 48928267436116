@import 'common/utils/mixins';

.boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
}

@include breakpoint(sm) {
  .boxes {
    display: inherit;
    margin-bottom: 40px;
  }
}
