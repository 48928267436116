.warning {
  margin: 20px 0;
}

.warningContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.refresh {
  text-decoration: underline;
  cursor: pointer;
}
