.subtitle {
  display: block;
  margin-bottom: 40px;
}

.errorAlert {
  margin-bottom: 40px;
}

.icon {
  display: flex;
  justify-content: center;
  margin: 60px 0 40px;
}
