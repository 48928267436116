.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  margin-bottom: 10px;
}

.historyItem {
  display: flex;
  justify-content: space-between;
}

.datetime {
  text-align: right;
}

.showMore {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
