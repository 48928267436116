.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 16px;
  gap: 17px;
  background: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 4px;
}

.input::placeholder {
  font-family: 'Inter';
}
