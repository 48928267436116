@import 'common/utils/mixins';

.emptySection {
  width: 500px;
  padding: 60px 80px;
  box-sizing: border-box;
}

.configSection {
  padding: 40px 60px;
}

@include breakpoint(xxl) {
  .emptySection {
    width: 390px;
    padding: 60px 40px;
  }
}

@include breakpoint(lg) {
  .configSection {
    padding: 30px 50px;
  }

  .emptySection {
    padding: 30px 20px;
  }
}

@include breakpoint(md) {
  .configSection {
    padding: 40px 20px;
    width: 100%;
  }

  .emptySection {
    padding: 0;
  }
}
