@import 'common/utils/mixins';

.container {
  max-height: 525px;
  display: grid;
  grid-template-columns: 48%;
  grid-gap: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}

.grid4to16 {
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
}

@include breakpoint(sm) {
  .grid4to16,
  .container {
    grid-template-columns: 100%;
    grid-column-gap: 0;
  }
}
