@import 'common/utils/mixins';

.headerRow {
  align-items: end;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 80px 2fr 2fr 2fr 3fr 20px;
  grid-template-areas: 'expand name network value switch switch';
  padding: 15px 0;
}

.headerCellWallet {
  grid-area: name;
  min-width: 100px;
}

.headerCellNetwork {
  grid-area: network;
  min-width: 100px;
}

.headerCellValue {
  grid-area: value;
  min-width: 100px;
}

.headerCellSorter {
  display: none;
}

.headerCellSwitch {
  grid-area: switch;
  justify-self: end;
}

@include breakpoint(sm) {
  .headerRow {
    border: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'sorter switch';
  }
  .headerCellWallet {
    display: none;
  }

  .headerCellNetwork {
    display: none;
  }

  .headerCellValue {
    display: none;
  }

  .headerCellSorter {
    display: initial;
    grid-area: sorter;
    padding-left: 20px;
  }
}
