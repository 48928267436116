@import 'common/utils/mixins';

.header {
  margin-bottom: 5px;
  display: block;
}

.subHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.sortFilter {
  display: flex;
  align-items: center;
  gap: 20px;
}
