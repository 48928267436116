@import 'common/utils/mixins';

.button {
  margin-top: 40px;
}

.address {
  word-break: break-all;
}

.name {
  margin: 40px 0 20px 0;
}

.subheader {
  margin-top: -20px;
}
