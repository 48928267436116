@import 'common/utils/mixins';

.container {
  display: flex;
  gap: 15px;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;
}

@include breakpoint(md) {
  .buttons {
    display: none;
  }

  .userMenuWrapper {
    display: none;
  }
}
