@import 'common/utils/mixins';

.input {
  margin-bottom: 10px;
}

.wallet {
  min-width: 160px;
  width: fit-content;
  margin-bottom: 30px;
}

.error {
  margin-bottom: 20px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

@include breakpoint(sm) {
  .wallet {
    width: auto;
  }
}

.summary {
  margin-bottom: 30px;
}
