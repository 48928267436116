@import 'common/utils/mixins';

.container {
  display: grid;
  gap: 10px;
  max-width: 1500px;
  margin-left: 60px;
  grid-template-columns: 3fr 2fr 2fr;
}

@include breakpoint(xl) {
  .container {
    margin-left: 0;
  }
}

@include breakpoint(md) {
  .container {
    grid-template-columns: 1fr;
    gap: 20px 10px;
  }
}
