@import 'common/utils/mixins';

.rootContainer {
  margin-bottom: 30px;
}

.disabled {
  background-color: var(--background-light-input);
}

.container {
  width: 100%;
  box-sizing: border-box;
}

.inputContainer {
  padding: 10px;
  border: 1px solid var(--border-light);
  background: var(--background-light-input);
  border-radius: 4px;

  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;

  margin-bottom: 5px;
  position: relative;
  cursor: pointer;
}

.selectBtn {
  background: var(--background-light-input);
}

.maxButtonContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: 10px;
}

.inputContainerError {
  padding: 8px;
  border: 3px solid var(--border-error);
  margin-bottom: 0;
}

.inputContainerInfo {
  padding: 8px;
  border: 3px solid var(--border-input-info);
  margin-bottom: 0;
}

.amountContainer {
  height: 60px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  cursor: text;
}

.amountInput {
  padding: 5px;
  text-align: right;
  background: transparent;
}

.amountFiat {
  padding-right: 5px;
}

.hideOnDesktop {
  display: none;
}

.desktopWalletSelector {
  margin-top: 10px;
}

.inputCurrency {
  display: none;
}

@include breakpoint(sm) {
  .inputContainer {
    padding: 0 10px;
  }

  .amountInput {
    padding: 0;
    width: 100%;
  }

  .amountFiat {
    padding-right: 0;
  }

  .hideOnMobile {
    display: none;
  }

  .hideOnDesktop {
    display: block;
  }

  .inputContainer {
    gap: 2px;
  }

  .amountFiat {
    overflow: auto;
  }

  .removeBorderOnSmall {
    border: none;
  }

  .hideText {
    display: none;
  }

  .containerMargin {
    margin-bottom: 30px;
  }

  .mobileWalletSelector {
    margin: 20px 0;
  }

  .maxButtonContainer {
    margin-left: 0;
  }

  .inputCurrency {
    display: inline;
  }

  .inputWithCurrency {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: nowrap;
  }
}
