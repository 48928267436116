@import 'common/utils/mixins';

.container {
  background: var(--background-medium);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 40px;
}

.header {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  padding: 10px 0;
}

.sortContainer {
  padding: 3px;
  border-radius: 50px;
  background: var(--background-secondary);
}

.sort {
  align-items: center;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  padding: 8px 0;
  width: 80px;
}

.donations {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 20px;
}

.donation {
  border-radius: 40px;
  padding: 10px 14px;
  background: var(--background-light);
  display: flex;
  align-items: center;
  gap: 5px;
}

.donatorsName {
  flex-grow: 1;
  word-break: break-word;
}

@include breakpoint(lg) {
  .donations {
    grid-template-columns: 1fr;
  }
}

@include breakpoint(md) {
  .donations {
    grid-template-columns: 1fr 1fr;
  }
}

@include breakpoint(sm) {
  .container {
    padding: 20px 30px;
  }

  .donations {
    grid-template-columns: 1fr;
  }
}
