@import 'common/utils/mixins';

.background {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.contentContainer {
  background: var(--background-light);
  border-radius: 12px;
  box-shadow: 0px 2px 30px var(--background-overlay);
  margin: 40px 0 20px;
  padding: 60px 100px;
  width: 650px;
  z-index: 400;
}

@include breakpoint(md) {
  .contentContainer {
    padding: 60px 50px;
  }
}

@include breakpoint(sm) {
  .contentContainer {
    border-radius: 0;
    border-top: 1px solid var(--background-overlay);
    box-sizing: border-box;
    box-shadow: none;
    margin-top: 0;
    padding: 40px 20px 20px;
    width: 100vw;
  }
}
