.container {
  margin-top: 40px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.list {
  margin-top: 20px;
}
