.container {
  padding-bottom: 20px;
}

.trackContainer {
  background: var(--background-light);
  height: 25px;
  width: 100%;
  border-radius: 100%;
  cursor: default;
}

.track {
  height: 25px;
  width: 100%;
  border-radius: 25px;
  margin: auto;
  cursor: default;
}

.thumb {
  min-width: 0;
  min-height: 0;
  margin-top: -5px;
  cursor: default !important;
}

.thumb:focus {
  outline: none;
}

.text {
  margin-left: 5px;
}
