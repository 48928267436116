.container {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
}

.checkmarkContainer {
  border-radius: 3px;
  border: 1px solid var(--border-light);
  height: 21px;
  max-height: 21px;
  max-width: 21px;
  min-height: 21px;
  min-width: 21px;
  width: 21px;
}

@media (hover: hover) {
  .checkmarkContainer:hover {
    opacity: 0.7;
  }
}

.checkmark {
  height: 21px;
  max-height: 21px;
  max-width: 21px;
  min-height: 21px;
  min-width: 21px;
  width: 21px;
}

.input:focus + .checkmarkContainer {
  border: 1px solid var(--border-light-focus) !important;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
