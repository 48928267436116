.container {
  border-radius: 42px;
  background: var(--infobox-fill, var(--background-secondary));
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 3px;
}

.topbar {
  text-align: center;
  padding: 8px 0;
  border-radius: 30px;
  width: 80px;
  cursor: pointer;
}

.selectedTopBar {
  border-radius: 30px;
  background: var(--logo-yellow, var(--background-checkbox-checked));
}
