@import 'common/utils/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.customBox {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr 45px;
  gap: 5px;
  margin-top: 8px;
}

.box {
  padding: 8px 8px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.activeBox {
  background: var(--background-theme-gradient);
  box-shadow: 0px 6px 10px var(--background-theme-shadow-opacity3);
  border-radius: 4px;
}

.input {
  padding: 4px 16px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  width: calc(100% - 45px) !important;
}

.edit {
  cursor: pointer;
  text-decoration: underline;
  margin: 0 20px;
}

.errorInput {
  background: var(--background-error);
  border: 3px solid var(--border-error);
}

@include breakpoint(md) {
  .customBox {
    max-width: 300px;
  }
}

@include breakpoint(sm) {
  .customBox {
    max-width: none;
  }
}
