.container {
  margin-bottom: 40px;
}

.smallContainer {
  margin-bottom: 20px;
}

.headerContainer {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.textContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.alertContainer {
  margin: 25px 0 20px;
}

.subtitle {
  margin-top: 20px;
}

.closeButtonContainer {
  height: 24px;
  width: 24px;
  padding-top: 15px;
  cursor: pointer;
}

.closeButtonSmallContainer {
  padding-top: 0;
}

.closeButtonSmall {
  height: 12px;
  width: 12px;
}

.arrowBack {
  cursor: pointer;
}

.arrowBackSmall {
  width: 20px;
  height: auto;
}
