.container {
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 15px 0 0;
}
