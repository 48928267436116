@import 'common/utils/mixins';

.container {
  height: 100vh;
  max-width: 100%;
}

.topContainer {
  align-items: center;
  background-color: var(--background-navbar-light);
  display: flex;
  height: 90px;
  padding: 0 40px;
  box-sizing: border-box;
  width: 100%;
}

.topContainer.dark {
  background-color: var(--background-navbar-dark);
}

.bottomContainer {
  height: calc(100vh - 90px);
  overflow-y: auto;
}

.content {
  padding: 100px 0;
  margin: 0 auto;
  width: 1040px;
}

@include breakpoint(lg) {
  .content {
    width: 850px;
  }
}

@include breakpoint(md) {
  .topContainer {
    padding: 0 20px;
  }

  .content {
    width: unset;
    padding: 0;
    margin: 20px;
  }
}
