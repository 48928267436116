@import 'common/utils/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  padding: 15px 20px;
  height: fit-content;
  height: auto;
}

@media (hover: hover) {
  .memberContainer:hover {
    cursor: pointer;
    background: var(--background-medium-hover);
    box-shadow: none;
  }
}

.containerSelected,
.ownerContainer {
  background: var(--background-theme-gradient);
  box-shadow: 0px 6px 14px var(--background-theme-shadow-opacity2);
  border: 1px solid transparent;
}

.ownerContainer {
  background: var(--background-theme-gradient-light);
}

@include breakpoint(md) {
  @media (hover: hover) {
    .memberContainer:hover {
      background: none;
    }

    .containerSelected:hover {
      background: var(--background-theme-gradient);
      box-shadow: 0px 6px 14px var(--background-theme-shadow-opacity2);
      border: 1px solid transparent;
    }
  }
}

.circleIcon {
  min-width: 22px;
}
