@import 'common/utils/mixins';

.networkContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  margin-top: 40px;
}

.subheader {
  margin-bottom: 20px;
  display: block;
}

.notSelectable {
  cursor: default;
  border: 1px solid var(--background-medium) !important;
  background: var(--background-medium) !important;
}

.networkContainerMobile {
  display: none;
}

@media (hover: hover) {
  .notSelectable:hover {
    background: var(--background-medium) !important;
  }
}

@include breakpoint(sm) {
  .networkContainer {
    grid-template-columns: 1fr;
  }

  .currencies {
    margin-top: 20px;
  }
}
