@import 'common/utils/mixins';

.container {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 550px;
  max-width: calc(100vw - 20px);
  z-index: 500;
}

@include breakpoint(sm) {
  .container {
    min-width: unset;
  }
}
