@import 'common/utils/mixins';

.bodyScrollLock {
  height: 100%;
  overflow: hidden;
}

.background {
  align-items: center;
  background: var(--background-overlay);
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 399;
}

.contentContainer {
  background: var(--background-light);
  width: 850px;
  padding: 60px 100px;
  max-height: 100vh;
  box-shadow: 0px 0px 18px var(--border-overlay);
  box-sizing: border-box;
  overflow-y: auto;
  border-radius: 12px;
  z-index: 400;
}

@include breakpoint(md) {
  .contentContainer {
    padding: 60px;
    width: 800px;
  }
}

.smallModal {
  right: calc(50% - 25vw);
  width: 400px;
  padding: 30px 60px 50px;
  min-height: 680px;
}

@include breakpoint(sm) {
  .contentContainer {
    box-sizing: border-box;
    border: 0;
    border-radius: 0;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 40px 20px 80px;
    border-top: 1px solid var(--background-overlay);
    overflow-y: auto;
  }

  .smallModal {
    min-width: initial;
  }
}
