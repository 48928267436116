@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  padding: 20px;
  border-radius: 8px;
  margin: 10px 0;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 15px 5px;
}

@include breakpoint(sm) {
  .container {
    grid-template-columns: 1fr;
  }
}
