.container {
  background-color: var(--background-medium);
  border-radius: 4px;
  gap: 10px;
}

.info {
  display: block;
  padding: 30px 30px 0;
}

.summary {
  padding-bottom: 30px;
}
