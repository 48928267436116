.term {
  margin: 40px 0;
}

.alert {
  margin: 20px 0;
}

.button {
  width: 100%;
}
