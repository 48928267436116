@import 'common/utils/mixins';

.boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

@include breakpoint(xxl) {
  .boxes {
    grid-template-columns: 1fr 1fr;
  }
}

@include breakpoint(md) {
  .boxes {
    grid-template-columns: 1fr;
  }
}
