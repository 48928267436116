@import 'common/utils/mixins';

.container {
  background-color: var(--background-medium);
  border-radius: 4px;
}

.funds {
  padding: 10px 30px 0;
}

.summary {
  padding-bottom: 10px;
}

.currencies {
  padding: 0 30px 10px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.warning {
  padding: 20px 30px;
  background-color: var(--background-medium);
  border-radius: 4px;
  margin-top: 20px;
}
