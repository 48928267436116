.container {
  display: inline-flex;
  position: relative;
}

.popupTrigger {
  align-items: center;
  border: 1px solid var(--border-medium);
  border-radius: 20px;
  display: inline-flex;
  gap: 8px;
  padding: 4px 16px;
  cursor: pointer;
}

.modeDotsContainer {
  display: inline-flex;
}

.modeDot {
  height: 6px;
  min-height: 6px;
  min-width: 6px;
  width: 6px;
  border-radius: 6px;
}

.modeDot + .modeDot {
  margin-left: -3px;
}

.modeDotAvailable {
  background-color: var(--balancechart-available);
}

.modeDotTotal {
  background-color: var(--balancechart-total);
}

.popup {
  background-color: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 8px;
  box-shadow: var(--common-box-shadow);
  padding: 20px;
  position: absolute;
  right: 0;
  top: 28px;
  z-index: 10;
}

.popupItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  min-width: 120px;
}

.popupItem:last-child {
  margin-bottom: 0;
}
