.container {
  line-height: 0;
  position: relative;
  z-index: 10;
}

.menuContainer {
  background-color: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 8px;
  box-shadow: var(--common-box-shadow);
  min-width: fit-content;
  white-space: nowrap;
  position: absolute;
  top: 10px;
  padding: 20px;
}

.positionLeft {
  left: 0px;
}

.positionRight {
  right: 0px;
}

.sortItems {
  margin: 10px 0;
}

.sortItems:first-child {
  margin-top: 0;
}

.sortItems:last-child {
  margin-bottom: 0;
}
