@import 'common/utils/mixins';

.container {
  display: grid;
  grid-template-columns: 1fr 80px;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mainButtons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 700px;
  height: auto;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}

.filterButton {
  width: 80px;
}

@include breakpoint(lg) {
  .container,
  .mainButtons {
    gap: 5px;
  }
}

@include breakpoint(sm) {
  .container {
    display: flex;
    justify-content: end;
  }

  .mainButtons {
    display: none;
  }

  .container {
    margin-bottom: 10px;
  }
}
