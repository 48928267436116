@import 'common/utils/mixins';

.rootContainer {
  margin-bottom: 30px;
}

.container {
  width: 100%;
  box-sizing: border-box;
}

.inputContainer {
  padding: 10px;
  border: 1px solid var(--border-light);
  border-radius: 4px;

  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;

  margin-bottom: 5px;
  background: var(--background-light);
  position: relative;
}

.inputReadonlyContainer {
  background: var(--background-light);
}

.readOnlyBox {
  cursor: default;
  background: var(--background-light);
  opacity: 0.6;
  position: absolute;
  margin-right: -12px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.maxButtonContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: 10px;
}

.amountContainer {
  height: 60px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  cursor: text;
}

.amountInput {
  padding: 5px;
  text-align: right;
  background: transparent;
}

.amountFiat {
  padding-right: 5px;
}

.hideOnDesktop {
  display: none;
}

.desktopWalletSelector {
  margin-top: 10px;
}

.selectCurrencyBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  min-width: 135px;
  cursor: pointer;
  position: relative;
}

.readOnlyMobileBox {
  cursor: default;
  background: var(--background-light);
  opacity: 0.6;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: -12px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

@include breakpoint(sm) {
  .hideOnMobile {
    display: none;
  }

  .hideOnDesktop {
    display: block;
  }

  .inputContainer {
    gap: 2px;
  }

  .hideText {
    display: none;
  }
}
