@import 'common/utils/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  gap: 5px;

  width: calc(100% - 30px);
  border: 1px solid var(--border-light);
  background: var(--background-light);
  border-radius: 4px;
  cursor: pointer;
}

@media (hover: hover) {
  .container:hover {
    background-color: var(--background-light-hover);
  }
}

.desktopText {
  display: initial;
}

.mobileText {
  display: none;
}

.desktopLgText {
  display: none;
}

@include breakpoint(lg) {
  .desktopText {
    display: none;
  }

  .mobileText {
    display: none;
  }

  .desktopLgText {
    display: initial;
  }
}

@include breakpoint(sm) {
  .desktopText {
    display: none;
  }

  .desktopLgText {
    display: none;
  }

  .mobileText {
    display: initial;
  }
}
