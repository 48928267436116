.container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 40px;
}

.header {
  align-items: center;
  display: flex;
  gap: 40px;
}

.arrowBack {
  cursor: pointer;
}

.actions {
  display: flex;
  flex-grow: 1;
  gap: 5px;
  justify-content: flex-end;
}
