@import 'common/utils/mixins';

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.btns {
  display: flex;
  align-items: center;
  gap: 10px;
}

@include breakpoint(lg) {
  .btns {
    display: flex;
    flex-direction: column;
  }

  .btn {
    width: 100%;
  }
}

@include breakpoint(md) {
  .container {
    display: flex;
    padding-top: 10px;
    gap: 5px;
  }

  .btns {
    display: flex;
    flex-direction: row;
  }

  .btn {
    width: fit-content;
  }
}
