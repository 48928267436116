@import 'common/utils/mixins';

.desktopContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 30px;
  background: var(--background-light-input);
  border: 1px solid var(--border-light);
  border-radius: 4px;
  margin: 10px 0 20px;
}

.mobileContainer {
  display: none;
}

@include breakpoint(md) {
  .textMargin {
    margin-right: 10px;
  }
}

@include breakpoint(sm) {
  .mobileContainer {
    display: block;
    gap: 20px;
    border-radius: 4px;
    margin: 20px 0;
  }

  .selectCurrencyMobile {
    background: var(--background-light-input);
    margin: 10px 0;
  }

  .textMargin {
    margin-bottom: 20px;
  }

  .desktopContainer {
    display: none;
  }
}
