@import 'common/utils/mixins';

.container {
  margin-top: 20px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 25px 3fr 2fr 2fr 220px;
  grid-template-areas: 'expand dates amounts approvals suffix';
}

.loader {
  display: flex;
  align-items: center;
}

.details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.dates {
  grid-area: dates;
}

.amounts {
  grid-area: amounts;
}

.approvals {
  grid-area: approvals;
  min-width: 50px;
}

@include breakpoint(lg) {
  .container {
    grid-template-columns: 25px 3fr 2fr 2fr 130px;
  }
}

@include breakpoint(md) {
  .container {
    margin-top: 0;
    grid-template-columns: 25px 2fr 1fr;
    grid-template-areas:
      'amounts amounts amounts'
      'dates dates dates'
      'approvals approvals approvals';
    padding-bottom: 10px;
    gap: 10px 5px;
  }

  .dates,
  .amounts,
  .approvals {
    grid-column-start: 2;
    grid-column-end: 6;
  }
}
