@import 'common/utils/mixins';

.container {
  height: 100%;
  background-color: var(--background-medium);
  position: relative;
}

.topContainer {
  align-items: center;
  background-color: var(--background-medium);
  display: flex;
  height: 90px;
  justify-content: space-between;
  padding: 0 40px;
  z-index: 1;
  position: absolute;
  top: 0;
  width: calc(100% - 80px);
}

.logotype {
  display: flex;
}

.logomark {
  display: none;
}

.mainContainer {
  padding-top: 90px;
  min-height: calc(100vh - 90px - 100px); // minus topbar minus footer
}

.footerContainer {
  height: 100px;
  box-sizing: border-box;
  padding: 0 40px 40px;
}

.backgroundIcon {
  position: absolute;
  bottom: 50px;
}

@include breakpoint(lg) {
  .topContainer {
    width: calc(100% - 40px);
    padding: 0 20px;
  }

  .footerContainer {
    height: 100px;
    padding: 0 20px 40px;
  }
}

@include breakpoint(md) {
  .backgroundIcon {
    display: none;
  }
}

@include breakpoint(sm) {
  .logotype {
    display: none;
  }

  .logomark {
    display: inherit;
  }

  .mainContainer {
    min-height: calc(100vh - 90px - 75px); // minus topbar minus footer
  }

  .footerContainer {
    height: 75px;
  }
}
