@import 'common/utils/mixins';

.mobileHeaderRow {
  display: none;
}

.tableItem {
  align-items: center;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 55px 1fr 1fr 2fr 1fr 180px;
  grid-template-areas: 'initials name surname email role actions';
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 15px 20px;
  margin: 5px 0;
  gap: 5px;
}

.tableItem:first-child {
  border: 0;
  border-radius: 0;
  margin: 0;
}

@media (hover: hover) {
  .tableItem:not(:first-child):hover {
    background-color: var(--background-light-hover);
  }
}

.cellInitials {
  grid-area: initials;
}

.cellName {
  grid-area: name;
}

.cellSurname {
  grid-area: surname;
}

.cellEmail {
  grid-area: email;
}

.cellRole {
  grid-area: role;
}

.cellActions {
  grid-area: actions;
  justify-self: stretch;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
}

@include breakpoint(lg) {
  .tableItem {
    grid-template-columns: 55px 20fr 25fr 35fr 20fr 90px;
    // min-height: 75px;
  }

  .cellActions {
    max-width: 90px;
  }
}

@include breakpoint(sm) {
  .headerRow {
    display: none;
  }

  .mobileHeaderRow {
    display: block;
    padding: 15px;
    gap: 10px;
  }

  .tableItem {
    grid-template-columns: 55px auto;
    grid-template-areas:
      'initials name'
      'initials surname'
      'initials email'
      'initials role'
      'actions actions';
    align-items: start;
    gap: 10px;
    border-top: 1px solid var(--border-light);
  }

  .cellActions {
    max-width: unset;
  }
}
