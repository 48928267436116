@import 'common/utils/mixins';

.container {
  padding: 40px 50px;
  width: fit-content;
  border: 1px solid var(--border-light);
  filter: drop-shadow(0px 12px 28px var(--border-confirmation-box-shadow));
  border-radius: 8px;
  margin-bottom: 10px;
}

.containerYellow {
  background: var(--background-theme-gradient);
  box-shadow: 0px 12px 28px var(--border-confirmation-box-shadow);
  filter: none;
}

.subTitle {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.textPending {
  width: 120px;
}

@include breakpoint(sm) {
  .container,
  .containerYellow {
    width: calc(100% - 80px);
    padding: 30px 40px;
    margin-bottom: 30px;
  }

  .textPending {
    width: 100%;
  }
}
