@import 'common/utils/mixins';

.chartMargin {
  margin-top: 54px;
}

@include breakpoint(xl) {
  .chartMargin {
    margin-top: 0;
  }
}
