.currencySelectorReadonly {
  border: 1px solid var(--border-light);
  padding: 10px 20px;
}

.qrCode {
  margin: auto;
}

.addressContainer {
  align-items: center;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 10px 30px;
  height: 80px;
  background: var(--background-secondary);
}

.address {
  word-break: break-all;
}

.copyIcon {
  cursor: pointer;
}

.copyIcon:hover {
  opacity: 0.7;
}

.info {
  align-items: center;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  padding: 20px 30px;
  background: var(--background-secondary);
}

.infoIcon {
  min-width: 15px;
  min-height: 15px;
}
