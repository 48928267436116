@import 'common/utils/mixins';

.topContainer {
  align-items: center;
  background-color: var(--background-navbar-dark);
  display: flex;
  height: 90px;
  justify-content: space-between;
  padding: 0 40px;
}

.bottomContainer {
  align-items: stretch;
  display: flex;
  flex-wrap: nowrap;
  min-height: calc(100vh - 90px);
}

.sideContentContainer {
  background-color: var(--background-light);
  border-right: 1px solid var(--border-light);
  width: 240px;
  min-width: 240px;
  box-sizing: border-box;
  overflow-y: auto;
}

.mainContentContainer {
  background-color: var(--background-dark);
  flex-grow: 1;
  max-width: calc(100vw - 240px);
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
  overflow-y: auto;
}

@include breakpoint(lg) {
  .topContainer {
    padding: 0 20px;
  }

  .sideContentContainer {
    width: 200px;
    min-width: 200px;
  }

  .mainContentContainer {
    max-width: calc(100vw - 200px);
  }
}

@include breakpoint(md) {
  .sideContentContainer {
    display: none;
  }

  .mainContentContainer {
    max-width: initial;
  }
}
