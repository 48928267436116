@import 'common/utils/mixins';

.iframeWrapper {
  position: relative;
}

.iframeWrapper iframe {
  width: 480px;
  height: 720px;
  max-height: 100vh;
}

.customizedModal {
  padding: 0px !important;
  width: 480px !important;
  line-height: 0;
}

.closeButtonContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}

.closeButton {
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
}

.closeButton:hover {
  background-color: rgba(232, 233, 237, 0.5);
  transition: background-color 200ms ease 0s;
}

@include breakpoint(sm) {
  .iframeWrapper iframe {
    height: 100vh !important;
    width: 100vw !important;
  }

  .customizedModal {
    width: 100vw !important;
  }
}
