.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
  gap: 40px;
}

.arrowBack {
  cursor: pointer;
}
