@import 'common/utils/mixins';

.subsections {
  margin-left: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.threshold {
  display: flex;
  align-items: center;
  gap: 10px;
}
.marginTop {
  margin-top: 30px;
}

@include breakpoint(sm) {
  .threshold {
    display: inherit;
  }
}
