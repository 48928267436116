@import 'common/utils/mixins';

.headerRow {
  align-items: end;
  display: grid;
  // Values have been added to the sides which correspond to a padding on the left and right of 20px.
  // The resulting value is 15px because 15px + 5px(gap) = 20px
  grid-template-columns: 15px 2fr 2fr 2fr 3fr 15px;
  grid-template-areas: 'empty asset wallet amount switch switch';
  padding: 15px 0;
  column-gap: 5px;
}

.headerCellAsset {
  grid-area: asset;
  min-width: 130px;
}

.headerCellWallet {
  grid-area: wallet;
}

.headerCellAmount {
  grid-area: amount;
}

.headerCellSorter {
  display: none;
}

.headerCellSwitch {
  grid-area: switch;
  justify-self: end;
}

@include breakpoint(sm) {
  .headerRow {
    border: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'sorter switch';
  }

  .headerCellAsset {
    display: none;
    min-width: none;
  }

  .headerCellWallet {
    display: none;
  }

  .headerCellAmount {
    display: none;
  }

  .headerCellSorter {
    display: initial;
    grid-area: sorter;
    padding-left: 20px;
  }
}
