.container {
  background: var(--background-warning);
  border-radius: 6px;
  box-shadow: var(--border-box-shadow);
  padding: 15px;
}

.header {
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  align-items: center;
}
