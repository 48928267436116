.lastItem {
  margin-bottom: 40px;
}

.summaryButton {
  width: 100%;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.textRight {
  text-align: right;
}

.warningAlert {
  margin-bottom: 20px;
}

.priceChangeTolerance {
  margin-bottom: 10px;
}
