.customCheckbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.customCheckbox .checkmark {
  width: 16px;
  height: 16px;
  border: 1px solid var(--border-light);
  background: var(--background-light);
  border-radius: 3px;
}

.input:checked + .checkmark {
  background: var(--btn-dark) url('../assets/check.svg') center no-repeat;
  border: 1px solid var(--border-dark);
}

.input:focus + .checkmark {
  border: 1px solid var(--border-light-focus);
}

.input:focus:checked + .checkmark {
  border: 1px solid var(--border-light-focus);
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.customCheckbox .errorCheckmark {
  background: var(--background-error);
  border: 1px solid var(--border-error);
}
