@import 'common/utils/mixins';

.layout {
  justify-content: center;
}

.box {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  margin: 20px 0;
}

.texts {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.apr {
  min-width: 200px;
  box-shadow: var(--border-box-shadow);
  cursor: default;
}

.header {
  display: flex;
  align-items: center;
  gap: 5px;
}

.alternativeOffer {
  border: 1px solid var(--border-theme);
  box-shadow: 0px 6px 10px rgba(223, 208, 131, 0.25);
}

@include breakpoint(md) {
  .container {
    border: 1px solid var(--border-theme);
    box-shadow: 0px 6px 10px rgba(223, 208, 131, 0.25);
    border-radius: 10px;
    padding: 40px;
  }

  .alternativeOffer {
    border: none;
    box-shadow: none;
  }

  .apr {
    min-width: none;
    width: 100%;
  }
}

@include breakpoint(sm) {
  .box {
    grid-template-columns: 1fr;
  }
}
