@import 'common/utils/mixins';

.ltvAndHistory {
  margin-top: 50px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  min-width: 225px;
}

.header {
  align-items: start;
}

@include breakpoint(md) {
  .buttons {
    min-width: auto;
    max-width: 115px;
    flex-wrap: wrap;
  }

  .button {
    width: 100%;
  }
}

@include breakpoint(sm) {
  .ltvAndHistory {
    gap: 40px;
    grid-template-columns: 1fr;
  }

  .buttons {
    min-width: 100%;
    max-width: auto;
  }

  .button {
    width: fit-content;
  }
}
