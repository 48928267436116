@import 'common/utils/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding: 10px 30px;
  gap: 10px;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  min-width: 135px;
  margin-top: 20px;
  cursor: pointer;

  fieldset {
    border: none !important;
  }
}

.memberRow {
  display: block;
  width: 100%;
  padding: 10px 30px;
  white-space: normal;
  word-break: break-word;
  cursor: pointer;
}

@media (hover: hover) {
  .memberRow:hover {
    background-color: var(--background-light-hover);
  }
}

.select {
  margin-top: 12px;
  width: 650px;
  margin-left: 5px;
}

@include breakpoint(md) {
  .select {
    width: calc(100vw - 120px);
  }
}

@include breakpoint(sm) {
  .select {
    width: calc(100vw - 40px);
    margin-left: 5px;
  }
}
