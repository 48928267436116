@import 'common/utils/mixins';

.headerRow {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 3fr 3fr 6.2fr 200px;
  grid-template-areas: 'name network address actions';
  padding: 10px 5px;
}

.headerCellName {
  grid-area: name;
  min-width: 150px;
  padding-left: 15px;
}

.headerCellNetwork {
  grid-area: network;
  min-width: 150px;
}

.mobileSorter {
  display: none;
}

@include breakpoint(sm) {
  .headerRow {
    display: none;
  }

  .mobileSorter {
    display: initial;
    padding: 20px;
  }
}
