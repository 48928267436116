@import '../consts/breakpoints.module.scss';

@mixin breakpoint($point) {
  @if $point == xxl {
    @media only screen and (max-width: $breakpoint-xxl) {
      @content;
    }
  } @else if $point == xl {
    @media only screen and (max-width: $breakpoint-xl) {
      @content;
    }
  } @else if $point == lg {
    @media only screen and (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if $point == md {
    @media only screen and (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $point == sm {
    @media only screen and (max-width: $breakpoint-sm) {
      @content;
    }
  }
}
