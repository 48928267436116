@import 'common/utils/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 30px;
}

.trackContainer {
  background: var(--background-light);
  height: 25px;
  cursor: pointer;
  display: flex;
  width: 100%;
}

.track {
  height: 25px;
  width: 100%;
  border-radius: 25px;
  margin: auto;
}

.thumb {
  border-radius: 30px;
  height: 30px;
  width: 30px;
  min-width: 30px;
  min-height: 30px;
  background-color: var(--background-slider-thumb);
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumb:focus {
  outline: none;
}

.text {
  margin-top: 60px;
}

.btnAll {
  width: 100px;
}

@include breakpoint(sm) {
  .btnAll {
    display: none;
  }
}
