@import 'common/utils/mixins';

.container {
  width: 44px;
  height: 44px;
  min-height: 44px;
  min-width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-theme);
  border-radius: 50%;
}

.initialsButton {
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-navbar-light);
  border-radius: 50%;
  color: var(--text-navbar-light);
  cursor: pointer;
}

@media (hover: hover) {
  .initialsButton:hover {
    border: 1px solid var(--border-theme-light);
    color: var(--text-theme);
  }
}

@include breakpoint(md) {
  @media (hover: hover) {
    .initialsButton:hover {
      border: 1px solid var(--border-navbar-light);
      color: var(--text-navbar-light);
    }
  }
}
