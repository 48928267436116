.customTooltip {
  border: 1px solid var(--border-light) !important;
  border-radius: 5px !important;
  box-shadow: var(--common-box-shadow) !important;
  background: var(--background-light) !important;
  color: var(--text-primary) !important;
  padding: 10px !important;
  font-size: 11px !important;
  line-height: 13px !important;
  font-family: 'Inter' !important;
  font-weight: 400 !important;
  cursor: pointer;
}

.customArrow::before {
  border: 1px solid var(--border-light) !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15) !important;
  background: var(--background-light) !important;
}
