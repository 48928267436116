@import 'common/utils/mixins';

.dot {
  position: absolute;
  margin-left: 30px;
  z-index: 1;
}

.bellIcon {
  width: 40px;
  height: 40px;
  border: 1px solid var(--border-navbar-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('./assets/notifications.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

@media (hover: hover) {
  .bellIcon:hover {
    background-image: url('./assets/notifications-theme.svg');
    border: 1px solid var(--border-theme-light);
  }
}

.container {
  line-height: 0;
  position: relative;
}

.catchClickContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 199;
}

.menuContainer {
  background-color: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 8px;
  box-shadow: var(--common-box-shadow);
  min-width: 370px;
  position: absolute;
  top: 68px;
  right: -110px;
  padding: 40px;
  margin-bottom: 40px;
  z-index: 200;
  overflow-y: auto;
  height: calc(100vh - 180px);
}

@include breakpoint(lg) {
  .menuContainer {
    right: -90px;
  }
}

@include breakpoint(md) {
  @media (hover: hover) {
    .bellIcon:hover {
      background-image: url('./assets/notifications.svg');
      border: 1px solid var(--border-navbar-light);
      color: var(--text-navbar-light);
    }
  }

  .menuContainer {
    right: -75px;
  }
}

@include breakpoint(sm) {
  .menuContainer {
    display: flex;
    z-index: 200;
    min-width: 320px;
    width: calc(100vw - 80px);
    height: calc(100vh - 150px);
    top: 90px;
    right: 0;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    border-radius: 0;
    border: 0;
  }

  .closeIcon {
    background-image: url('../../common/assets/white-icons/close.svg') !important;
  }

  .catchClickContainer {
    top: 90px;
  }
}
