.container {
  padding: 2px;
  border-radius: 4px;
  border: 1px solid var(--border-light);
  display: flex;
  align-items: center;
  width: fit-content;
}

.button {
  padding: 12px 60px;
  gap: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.buttonActive {
  background: var(--background-theme-dark);
}

.readOnly {
  opacity: 0.6;
}

.readOnlyButton {
  cursor: default;
}
