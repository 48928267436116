.zeroEarnPillContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.zeroEarnPill {
  background: var(--borrowpill-liquidation-background);
  border-color: var(--borrowpill-liquidation-border);
}
