@import 'common/utils/mixins';
.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.modal {
  width: 275px;
}

.pickers {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.dateButton {
  width: calc(100% - 32px) !important;
  text-align: center;
}

@include breakpoint(sm) {
  .modal {
    width: calc(100% - 40px);
  }
}
