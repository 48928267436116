.container {
  position: relative;
  z-index: 10;
}

.menuContainer {
  background-color: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 8px;
  box-shadow: var(--common-box-shadow);
  min-width: fit-content;
  white-space: nowrap;
  position: absolute;
  top: 2px;
  padding: 20px;
  right: 0px;
}

.menuItem {
  display: block;
  margin-bottom: 15px;
  cursor: pointer;
}

.menuItem:last-child {
  margin-bottom: 0px;
}
