.container {
  width: 100%;
  height: 65px;
  position: relative;
}

.box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 4px;
  padding: 20px 30px 15px;
  margin-top: 5px;
  border: 1px solid var(--box-stroke, var(--border-light));
  background: var(--grey-fill, var(--background-light-input));
  cursor: pointer;
}

.disabledBox {
  position: absolute;
  background: var(--background-light);
  opacity: 0.8;
  border: 1px solid var(--box-stroke, var(--border-light));
  height: 63px;
  width: 100%;
  margin-right: -20px;
  border-radius: 4px;
}

.picker {
  float: right;
  left: -295px;
}

.icon {
  margin: -5px 0 0 5px;
}

.actions {
  padding: 10px 0 20px 0 !important;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 20px 15px 15px;
  cursor: pointer;
}

.errorInput {
  background: var(--background-error);
  border: 3px solid var(--border-error);
}
