@import 'common/utils/mixins';

.button {
  padding: 12px 16px;
  width: fit-content;
  border: 1px solid var(--border-light);
  background: var(--background-light);
  border-radius: 4px;
  cursor: pointer;
}

@media (hover: hover) {
  .button:hover {
    background-color: var(--background-light-hover);
  }
}

.selectedButton {
  border: 2px solid var(--border-light);
  background-color: var(--background-medium);
}
