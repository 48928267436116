@import 'common/utils/mixins';

.container {
  align-items: center;
  display: flex;
  gap: 8px;
  margin-right: 10px;
  padding: 12px 15px;
  border-radius: 6px;
  border: 1px solid var(--border-navbar-light);
  cursor: pointer;
  gap: 8px;
  display: flex;
  align-items: center;
}

@include breakpoint(md) {
  .container {
    margin-right: 0;
    margin-bottom: 10px;
    padding: 20px;
  }
}
