@import 'common/utils/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
  gap: 40px;
}

.arrowBack {
  min-width: 18px;
  cursor: pointer;
}

@include breakpoint(sm) {
  .header {
    gap: 20px;
  }
}
