@import 'common/utils/mixins';

.container {
  column-gap: 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}

.period {
  display: inline-block;
  padding: 4px 4px;
  cursor: pointer;
}

.activePeriod {
  border: 1px solid var(--border-medium);
  padding: 3px 3px;
  border-radius: 8px;
}

@include breakpoint(lg) {
  .container {
    column-gap: 10px;
  }
}
