.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field + .field {
  margin-top: 10px;
}

.lastField {
  margin-bottom: 40px;
}

.info {
  background: var(--background-info);
  border-radius: 4px;
  display: block;
  margin-bottom: 40px;
  padding: 10px;
}

.kycAlert {
  margin-bottom: 20px;
}

.summaryButton {
  width: 100%;
}

.textRight {
  text-align: right;
}
