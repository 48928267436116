@import 'common/utils/mixins';

.button {
  padding: 20px 30px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.secondaryButton {
  background: transparent;
  border: 1px solid var(--border-light);
}

@media (hover: hover) {
  .button:hover {
    opacity: 0.7;
  }

  .button.secondaryButton:hover {
    opacity: 0.5;
  }
}
