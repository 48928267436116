.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.thresholdContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
