@import 'common/utils/mixins';

.container {
  margin: 15px 20px;
}

.featureButton {
  display: none;
}

@include breakpoint(lg) {
  .container {
    margin: 10px 10px;
  }
}

@include breakpoint(md) {
  .container {
    margin: 15px 20px;
  }

  .featureButton {
    display: initial;
  }
}
