@import 'common/utils/mixins';

.container {
  max-height: 500px;
  overflow-y: scroll;
}

.hidden {
  display: none;
}

.termsConditionsLink {
  text-decoration: underline;
  cursor: pointer;
}

.lastItem {
  width: 100%;
  height: 1px;
  background-color: transparent;
}

.loaderContainer {
  height: 500px;
}

@include breakpoint(md) {
  .container {
    max-height: 400px;
  }

  .loaderContainer {
    height: 400px;
  }
}
