.content {
  background-color: var(--background-medium);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
}

.interestRate {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
