@import 'common/utils/mixins';

.container {
  background: var(--background-medium);
  border-radius: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  padding: 20px 40px;
}

.currenciesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@include breakpoint(sm) {
  .container {
    padding: 20px 30px;
  }
}
