@import 'common/utils/mixins';

.container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.iconReverse {
  transform: rotate(180deg);
}

@include breakpoint(sm) {
  .container {
    svg {
      transform: scale(0.8);
      margin-right: 10px;
    }
  }
}
