@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 30px;
}

.header {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.nameAndState {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.name {
  align-items: center;
  display: flex;
  gap: 8px;
}

.details {
  display: grid;
  grid-template-columns: 1fr 110px;
}

.earnedBox {
  display: grid;
  grid-template-columns: 100%;
  text-align: center;
  align-self: center;
}

.progressCircle {
  align-self: center;
}

.smallMargin {
  display: block;
  margin: 3px 0;
}

.bigMargin {
  margin: 10px 0;
}

.flexContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}

.summaryText {
  display: flex;
  flex-direction: column;
}

.progressCircleContainer {
  padding: 8px;
  display: flex;
  justify-content: center;
}

@include breakpoint(xl) {
  .container {
    padding: 20px;
  }
}

@include breakpoint(lg) {
  .flexContainer {
    display: inline-block;
  }
}

@include breakpoint(md) {
  .flexContainer {
    max-width: 200px;
  }
}
