.input {
  box-sizing: border-box;
  padding: 15px 0px 15px 30px;
  width: 100%;
  height: 60px;
  background: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 4px;
}

.input::placeholder {
  color: var(--text-secondary);
}

.input:active,
.input:focus {
  outline: none;
}

.errorInput {
  background: var(--background-error);
  border: 3px solid var(--border-error);
}
