@import 'common/utils/mixins';

.button {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 8px 10px;
}

@media (hover: hover) {
  .button:hover {
    border: 1px solid var(--border-light);
    border-radius: 5px;
    padding: 7px 9px;
  }
}

@include breakpoint(sm) {
  @media (hover: hover) {
    .button:hover {
      border: none;
      padding: 8px 10px;
    }
  }
}
