@import 'common/utils/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.mainContainer {
  margin-top: 20px;
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.secondaryContainer {
  margin-top: 20px;
  display: flex;
  gap: 30px;
  align-items: center;
}

.tables,
.links {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.links {
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
  min-width: 240px;
}

.header {
  align-items: start;
}

@include breakpoint(lg) {
  .mainContainer {
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
}

@include breakpoint(md) {
  .buttons {
    min-width: auto;
    max-width: 135px;
    flex-wrap: wrap;
  }

  .button {
    width: 100%;
  }
}

@include breakpoint(sm) {
  .mainContainer {
    gap: 20px;
    grid-template-columns: 1fr;
  }

  .secondaryContainer {
    gap: 5px;
    flex-direction: column;
    align-items: start;
  }

  .tables,
  .links {
    grid-template-columns: 1fr;
  }

  .links {
    gap: 60px;
  }

  .buttons {
    min-width: 100%;
    max-width: auto;
  }

  .button {
    width: fit-content;
  }
}
