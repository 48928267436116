@import 'common/utils/mixins';

.container {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  padding: 22.5px 100px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  background-color: var(--background-warning);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

@include breakpoint(lg) {
  .container {
    padding: 22.5px 50px;
  }
}

@include breakpoint(md) {
  .container {
    padding: 22.5px 20px;
  }
}
