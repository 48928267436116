@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  padding: 15px 20px;
  border-radius: 4px;
  margin: 5px 0;
}

.tableItem {
  display: grid;
  grid-template-columns: 25px 3fr 2fr 2fr 100px;
  grid-template-areas: 'expand name spend receive status';
  gap: 15px 5px;
  cursor: pointer;
}

.moreInfo {
  margin-top: 20px;
  grid-template-areas: 'expand wallets fee tolerance status';
}

.status {
  grid-area: status;
  min-width: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexGap10 {
  display: flex;
  overflow-wrap: anywhere;
  gap: 10px;
}

.spend {
  grid-area: spend;
}

.receive {
  grid-area: receive;
}

.expand {
  grid-area: expand;
  min-width: 25px;
  display: flex;
  align-items: center;
}

.wallets {
  grid-area: wallets;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.fee {
  grid-area: fee;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tolerance {
  grid-area: tolerance;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@include breakpoint(md) {
  .tableItem {
    grid-template-columns: 25px 2fr 1fr;
    grid-template-areas:
      'expand name status'
      'spend spend spend'
      'receive receive receive';
  }

  .moreInfo {
    grid-template-areas:
      'fee fee'
      'tolerance tolerance'
      'wallets wallets';
  }

  .spend,
  .receive,
  .wallets,
  .fee,
  .tolerance {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .status {
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@include breakpoint(sm) {
  .tableItem {
    grid-template-columns: 15px 2fr;
    grid-template-areas:
      'expand name '
      'status status '
      'spend spend '
      'receive receive ';
  }

  .status {
    justify-content: flex-start;
    align-items: flex-start;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .container {
    padding: 15px 10px;
  }

  .expand {
    grid-area: expand;
    min-width: 15px;
  }

  .moreInfo {
    margin-top: 10px;
    grid-template-areas:
      'fee fee'
      'tolerance tolerance'
      'wallets wallets';
  }
}
