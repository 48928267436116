.container {
  padding: 3px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.active {
  background: var(--pill-active-background);
  border: 1px solid var(--pill-active-border);
}

.completed {
  background: var(--pill-completed-background);
  border: 1px solid var(--pill-completed-border);
}

.created {
  background: var(--background-light);
  border: 1px solid var(--pill-active-border);
}

.terminated {
  background: var(--pill-terminated-background);
  border: 1px solid var(--pill-terminated-border);
}
