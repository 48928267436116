@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 30px;
}

.header {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
  align-items: flex-start;
}

.nameAndState {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
}

.state {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.buttons {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
}

.details {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.detailField {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.marginTop {
  margin-top: 10px;
}

@include breakpoint(xl) {
  .container {
    padding: 20px;
  }

  .buttonWidth {
    width: 100px;
  }
}
