@import 'common/utils/mixins';

.signer {
  padding: 5px 0;
}

.threshold {
  margin: 30px 0;
}

.signersBox {
  margin-top: 15px;
  max-height: 300px;
  overflow-y: auto;
}

.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@include breakpoint(lg) {
  .twoColumns {
    display: grid;
    grid-template-columns: 1fr;
  }
}
