.content {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.icon {
  min-width: 90px;
  min-height: 90px;
  align-self: center;
}
