@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  border-radius: 8px;
  padding: 15px 20px;
  margin: 5px 0;
}

@media (hover: hover) {
  .containerNotExpand:hover {
    background-color: var(--background-light-hover);
  }
}

.row {
  align-items: center;
  column-gap: 5px;
  display: grid;
  grid-template-columns: 60px 2fr 2fr 2fr 3fr;
  grid-template-areas: 'expand name network value address';
}

.row.rowExpanded {
  padding-bottom: 15px;
}

.clickable {
  cursor: pointer;
}

.cellExpand {
  grid-area: expand;
  cursor: pointer;
}

.cellName {
  grid-area: name;
  min-width: 100px;
  overflow-wrap: anywhere;
}

.cellNetwork {
  grid-area: network;
  min-width: 100px;
  overflow-wrap: anywhere;
  display: flex;
  align-items: center;
  gap: 10px;
}

.cellValue {
  grid-area: value;
  min-width: 100px;
  overflow-wrap: anywhere;
}

.cellAddress {
  grid-area: address;
  overflow-wrap: anywhere;
}

.cellPending {
  grid-column-start: 5;
  grid-column-end: 7;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

@include breakpoint(sm) {
  .container {
    padding: 15px 10px;
  }

  .row {
    grid-template-columns: 24px auto;
    grid-template-areas: 'expand name' 'network network' 'empty value' 'empty address';
    gap: 10px;
    align-items: start;
  }

  .cellExpand {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 0;
  }

  .cellPending {
    grid-column-start: 3;
    grid-column-end: 4;
  }
}
