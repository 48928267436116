@import 'common/utils/mixins';

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.name {
  display: flex;
  align-items: center;
  gap: 10px;
}

.details {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 25px 0;
}

.secondaryDetails {
  display: flex;
  gap: 30px;
  align-items: center;
}

.network {
  display: flex;
  gap: 5px;
  align-items: center;
}

.mainButtons {
  display: flex;
  gap: 10px;
  width: 100%;
}

@include breakpoint(lg) {
  .details {
    grid-template-columns: 1fr 1fr;
  }
}

@include breakpoint(sm) {
  .details {
    grid-template-columns: 1fr;
    gap: 10px;
    margin: 20px 0;
  }

  .secondaryDetails {
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }

  .maxWidth {
    width: 100%;
  }
}
