@import 'common/utils/mixins';

.topContainer {
  align-items: center;
  background-color: var(--background-navbar-dark);
  display: flex;
  height: 90px;
  justify-content: space-between;
  padding: 0 40px;
}

.mainContainer {
  min-height: calc(100vh - 90px);
  width: 100vw;
  background-color: var(--background-dark);
}

@include breakpoint(lg) {
  .topContainer {
    padding: 0 20px;
  }
}
