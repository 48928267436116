.container {
  border: 1px solid var(--border-light);
  padding: 12px 10px 8px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  cursor: pointer;
}

.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  margin-top: -5px;
}

.actions {
  padding: 10px 0 20px 0 !important;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 20px 15px 15px;
  cursor: pointer;
}
