@import 'common/utils/mixins';

.container {
  display: flex;
  gap: 20px;
  padding: 40px;
  align-items: flex-start;
}

.container + .container {
  padding-top: 0px;
}

@include breakpoint(lg) {
  .container {
    padding: 20px;
  }
}

@include breakpoint(md) {
  .container {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }
}
