.container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icons {
  display: flex;
  align-items: center;
  width: 45px;
}

.topIcon {
  margin-right: -5px;
  z-index: 1;
}
