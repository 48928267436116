.resendLink {
  text-decoration: underline;
  cursor: pointer;
}

.resendLinkLoader {
  display: inline;
  padding-left: 5px;
}

.alert {
  margin-bottom: 40px;
}
