.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--background-light);
  border-radius: 4px;
}

.divider {
  width: 100%;
  margin-bottom: 10px;
  height: 1px;
  background: var(--border-divider);
}
