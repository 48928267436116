@import 'common/utils/mixins';

.container {
  border: 1px solid var(--border-light);
  border-radius: 4px;
  padding: 40px 60px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.container:last-child {
  margin-bottom: 0;
}

.container.selected {
  background: var(--background-medium);
}

@media (hover: hover) {
  .container:hover {
    background-color: var(--background-light-hover);
  }

  .container.selected:hover {
    background: var(--background-medium-hover);
  }
}

.availableCurrencies {
  display: none;
}

@include breakpoint(sm) {
  .availableCurrencies {
    display: block;
    padding: 30px 0;
  }
}
