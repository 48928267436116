@import 'common/utils/mixins';

.container {
  display: grid;
  grid-template-columns: 1fr 45px;
  gap: 5px;
}

.containerBox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3px;
}

.containerBoxSmall {
  display: none;
}

.arrowBox {
  border: 1px solid var(--border-light);
  background-color: var(--background-light);
  border-radius: 4px;
  cursor: pointer;
  width: 45px;
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowBoxMobile {
  display: none;
}

@include breakpoint(lg) {
  .container {
    gap: 5px;
  }
}

@include breakpoint(md) {
  .container {
    gap: 40px;
  }
}

@include breakpoint(sm) {
  .container {
    grid-template-columns: 100%;
    gap: 20px;
    text-align: center;
  }

  .containerBox {
    display: none;
  }

  .containerBoxSmall {
    display: grid;
    grid-template-columns: 100%;
    gap: 5px;
  }

  .arrowBox {
    display: none;
  }

  .arrowBoxMobile {
    border: none;
    background-color: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
