.container {
  line-height: 0;
  position: relative;
}

.catchClickContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 199;
}

.menuContainer {
  background-color: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 8px;
  box-shadow: var(--common-box-shadow);
  min-width: 250px;
  position: absolute;
  top: 68px;
  right: -35px;
  padding: 40px;
  z-index: 200;
}
