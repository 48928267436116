@import 'common/utils/mixins';

.modal {
  line-height: 0;
  position: relative;
  z-index: 10;
}

.menuContainer {
  background-color: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 8px;
  box-shadow: var(--common-box-shadow);
  min-width: 220px;
  position: absolute;
  padding: 20px;
  z-index: 200;
}

@include breakpoint(sm) {
  .menuContainer {
    width: calc(100% - 40px);
  }
}
