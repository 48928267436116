.checkboxContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.walletAddressField {
  transition: all 0.5s ease-out;
  max-height: 0;
  overflow-y: hidden;
  margin-bottom: -24px;
}

.walletAddressFieldVisible {
  max-height: 200px;
  margin-bottom: 0;
}

.recaptcha {
  margin-top: 0;
}

.currencySelector {
  padding: 10px 20px;
}

.currencySelectorError {
  border: 3px solid var(--border-error);
  background: var(--background-error);
}
