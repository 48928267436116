@import 'common/utils/mixins';

.mainContainer {
  line-height: 0;
  position: relative;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  gap: 5px;

  width: calc(100% - 30px);
  border: 1px solid var(--border-light);
  background: var(--background-light);
  border-radius: 4px;
  cursor: pointer;
}

@media (hover: hover) {
  .container:hover {
    background-color: var(--background-light-hover);
  }
}

.catchClickContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 199;
}

.bodyScrollLock {
  height: 100%;
  overflow: hidden;
}

.menuContainer {
  z-index: 200;
  width: 360px;
  padding: 40px;
  height: calc(100vh - 170px);
  top: 90px;
  right: 0;
  position: fixed;
  background: var(--background-light);
  overflow: auto;
  box-shadow: 0px 0px 8px var(--border-overlay);
  border-left: 1px solid var(--border-light);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 30px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.button {
  width: 100%;
}

.mainButtons {
  display: none;
}

@include breakpoint(lg) {
  .menuContainer {
    height: calc(100% - 190px);
    padding-bottom: 60px;
  }
}

@include breakpoint(sm) {
  .menuContainer {
    width: calc(100% - 40px);
    height: calc(100% - 130px);
    padding: 20px;
  }

  .mainButtons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 40px;
  }

  .catchClickContainer {
    top: 90px;
  }
}
