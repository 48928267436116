@import 'common/utils/mixins';

.description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 40px;
}

.icon {
  margin: 0 5px 2px 0;
}

@include breakpoint(sm) {
  .description {
    flex-direction: column;
    align-items: start;
  }
}
