@import 'common/utils/mixins';

.container {
  display: flex;
  padding: 20px 30px;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  background: var(--background-medium);
  margin-top: 10px;
  height: calc(100% - 40px);
  gap: 10px;
}

.iconCopy {
  cursor: pointer;
  min-width: 10.364px;
}

.linkText {
  display: block;
  word-break: break-all;
}

.clickable {
  cursor: pointer;
}

@include breakpoint(lg) {
  .container {
    padding: 20px;
  }
}
