@import 'common/utils/mixins';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'networks currencies';
  column-gap: 50px;
}

.networkSelectors {
  grid-area: networks;
}

.subheader {
  margin-bottom: 20px;
  display: block;
}

.networkSupportedCurrencies {
  grid-area: currencies;
}

.errorAlert {
  margin-bottom: 40px;
}

@include breakpoint(sm) {
  .container {
    grid-template-columns: auto;
    grid-template-areas: 'networks';
  }

  .networkSupportedCurrencies {
    display: none;
  }
}
