@import 'common/utils/mixins';

.container {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(10, 10%);
  width: 100%;
  gap: auto;
  flex-wrap: wrap;
  margin: 10px 0;
}

@include breakpoint(sm) {
  .container {
    grid-template-columns: repeat(5, 20%);
  }
}
