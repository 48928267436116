.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.logo {
  height: 13px;
}
