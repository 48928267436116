@import 'common/utils/mixins';

.container {
  display: flex;
  align-items: center;
  margin: 20px 0 5px;
  gap: 10px;
}

.input {
  height: 70px;
  width: 50px !important;
  padding: 10px !important;
  text-align: center;
  box-sizing: border-box;
  padding: 15px 0px 15px 30px;
  background: var(--background-light);
  border: 1px solid var(--border-light);
  border-radius: 4px;
}

.input::placeholder {
  color: var(--text-secondary);
}

.input:active,
.input:focus {
  outline: none;
}

.errorInput {
  background: var(--background-error);
  border: 3px solid var(--border-error);
}

.containerError {
  gap: 6px;
}

.errorText {
  display: block;
}

.bottomText {
  display: flex;
  align-items: center;
  gap: 10px;
}

.clear {
  text-decoration: underline;
  cursor: pointer;
}

@include breakpoint(sm) {
  .container {
    gap: 5px;
  }

  .input {
    height: 60px;
    width: 45px !important;
    padding: 5px !important;
  }
}
