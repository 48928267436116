.tooltip {
  background: var(--background-medium);
  border: 1px solid var(--border-light);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}

.tooltipBalance {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: space-between;
}

.noDataPlaceholder {
  align-items: center;
  display: flex;
  justify-content: center;
}
