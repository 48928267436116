@import 'common/utils/mixins';

.container {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 16px;
}

.textWithIcon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  margin-left: 20px;
}

.withNotification {
  justify-content: space-between;
}

@media (hover: hover) {
  .container:hover {
    border: 1px solid var(--border-theme);
  }
}

.containerCurrentUrlMatched {
  background: var(--background-info);
}

.link {
  text-decoration: none;
}

@include breakpoint(lg) {
  .container {
    padding: 10px;
  }
}

@include breakpoint(md) {
  .container {
    padding: 16px;
  }

  .withNotification {
    justify-content: left;
  }
}
