@import 'common/utils/mixins';

.container {
  width: fit-content;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-wrap: break-word;
  gap: 15px;
}

.subTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.subText {
  max-width: 190px;
}

.textOutOf {
  min-width: 140px;
}

@include breakpoint(sm) {
  .container {
    width: 100%;
    margin-top: 40px;
  }

  .subText {
    max-width: 300px;
    text-align: right;
  }
}
